import React from 'react';
import { StyledBannerContainer } from './styles';
import { Image } from '@ngw6/us-components';

interface WSEBannerProps {
  wseBanner: WSEBanner;
}

interface WSEBanner {
  desktopImage?: string;
  mobileImage?: string;
  enabled: boolean;
}

const WSEBanner = ({ wseBanner }: WSEBannerProps) => {
  const { desktopImage, mobileImage, enabled } = wseBanner;
  const defaultImage = desktopImage || mobileImage;

  if (!enabled || !defaultImage) return null;

  return (
    <StyledBannerContainer className="banner-container">
      <Image
        src={desktopImage || defaultImage}
        className="desktop-banner"
        alt="desktop banner"
      />
      <Image
        src={mobileImage || defaultImage}
        className="mobile-banner"
        alt="mobile banner"
      />
    </StyledBannerContainer>
  );
};

export default WSEBanner;
