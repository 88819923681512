import {Breakpoints, styled} from '@ngw6/us-components';

export const StyledImageContainer: any = styled.div`
  img {
    position: absolute;
    width: auto;
    height: 230px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: ${Breakpoints.b960}px) {
      position: inherit;
      width: 100%;
      height: auto;
      left: 0;
      top: 0;
      transform: none;
    }
  }
`;

export const StyledMastheadContainer: any = styled.div`
  width: 100%;
  height: 230px;
  position: relative;

  @media (min-width: ${Breakpoints.b960}px) {
    height: auto;
  }
`;

export const StyledOpacityWrapper: any = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.55;
`;

export const StyledTitleWrapper: any = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
  padding: 24px 30px;

  @media (min-width: ${Breakpoints.b1280}px) {
    padding: 47px 106px;
  }
`;

export const StyledSingleLineTitleWrapper: any = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: end;
  column-gap: 0.75em;
  color: #ffffff;
  padding: 24px 30px;

  @media (min-width: ${Breakpoints.b1280}px) {
    padding: 47px 106px;
  }
`;
