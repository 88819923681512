import {
  Breakpoints,
  ButtonAppearance,
  ButtonCustomLink,
  CCustomerCenter,
  DisableFocusWrapper,
  Link,
  PinPlace,
  TextAppearance,
  TextColor,
  TextCustom,
  TextTag,
  TextWeight,
  buttonPaddingGenerator,
  useIsMobile
} from '@ngw6/us-components';
import React from 'react';
import DealerInformationDetail from '../../../../../components/dealer-information-detail';
import {DealerModel} from '../../../../../hooks-store/typings/incentive-store';
import {
  DealerCardContainer,
  DealerInfoContainer,
  DealerSiteContainer,
  NameContainer,
  DistanceContainer
} from './styled';
import {useTranslations} from '../../../../../services/use-translations';

interface DealerCardProps {
  dealer?: DealerModel;
  setActiveDealer?: (dealer?: DealerModel, showModal?: boolean) => void;
  onHandleDealerTracking: (dealer?: DealerModel, name?: string, type?: string) => void;
}

const DealerCard = ({
  dealer,
  setActiveDealer,
  onHandleDealerTracking
}: DealerCardProps): JSX.Element => {
  const isMobile = useIsMobile(Breakpoints.b560);
  const translations = useTranslations().tier2.landing.findDealer.dealerList;

  return (
    <DealerCardContainer id={dealer?.dealerid}>
      <NameContainer
        onClick={setActiveDealer && setActiveDealer.bind(null, dealer, false)}
      >
        <TextCustom
          appearance={TextAppearance.headline200}
          style={{color: TextColor.inherit, fontWeight: TextWeight.bold}}
          tag={TextTag.h3}
        >
          {dealer?.name}
        </TextCustom>
      </NameContainer>
      <DistanceContainer>
        <TextCustom
          appearance={TextAppearance.label150}
          style={{color: TextColor.primary}}
          tag={TextTag.span}
        >
          {dealer?.distance.toFixed(2)} miles away
        </TextCustom>
      </DistanceContainer>
      <DealerInfoContainer>
        <DealerInformationDetail
          Icon={PinPlace}
          label1={dealer?.address1}
          label2={`${dealer?.city}, ${dealer?.state} ${dealer?.postalcode}`}
        />
        <DealerInformationDetail
          Icon={CCustomerCenter}
          label1={
            isMobile ? (
              <Link href={dealer?.mobileTel} isInline>
                <TextCustom
                  appearance={TextAppearance.label150}
                  style={{fontWeight: TextWeight.bold}}
                >
                  {dealer?.prettyTel}
                </TextCustom>
              </Link>
            ) : (
              dealer?.prettyTel
            )
          }
        />
      </DealerInfoContainer>

      <DealerSiteContainer>
        <DisableFocusWrapper>
          <ButtonCustomLink
            appearance={ButtonAppearance.Primary}
            href={dealer?.url}
            padding={buttonPaddingGenerator(0, 28, 28, 28)}
            target="_blank"
            onClick={onHandleDealerTracking.bind(
              null,
              dealer,
              translations.dealerCardWebsite,
              'button click'
            )}
          >
            <TextCustom appearance={TextAppearance.label150}>
              {translations.dealerCardWebsite}
            </TextCustom>
          </ButtonCustomLink>
        </DisableFocusWrapper>
      </DealerSiteContainer>
    </DealerCardContainer>
  );
};

export default DealerCard;
