import {
  Breakpoints,
  ButtonAppearance,
  ButtonCustom,
  CustomImage,
  DisableFocusWrapper,
  LinkButton,
  Text,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  buttonPaddingGenerator,
  ButtonCustomLink
} from '@ngw6/us-components';
import React from 'react';
import IncentiveConstants from '../../../../../hooks-store/incentive-store/actions/incentive-constants';
import {useStore} from '../../../../../hooks-store/store';
import {
  DealerModel,
  OffersModel,
  ImageOfferData
} from '../../../../../hooks-store/typings/incentive-store';
import {AppConstants} from '../../../../../utils/app-constants';
import {
  clearText,
  getContrastColorText,
  getDateFormat,
  getOfferTypeLabelInfo,
  transformIrisUrl
} from '../../../../../utils/general';
import {
  StyledBackground,
  StyledButtonSection,
  StyledButtonWrapper,
  StyledImagePlaceholder,
  StyledImageWrapper,
  StyledInformationOfferContainer,
  StyledInformationWrapper,
  StyledOfferCardWrapper,
  StyledOfferMicroText,
  StyledOfferSubtitle,
  StyledOfferTitle,
  StyledOfferTypeLabel,
  StyledOfferTypeLabelWrapper,
  StyledOpacityWrapper,
  StyledSectionButton,
  StyledShowOfferDetails,
  StyledTextWrapper
} from './styled';
import {useTranslations} from '../../../../../services/use-translations';
import {FeatureAppTrackingManager} from '../../../../../context/use-tracking-manager';
import {onHandleFormButtonTracking} from '../../../../../utils/tagging-helpers';
import {useFeatureAppConfig} from '../../../../../services/use-feature-app-config';

interface OfferCardProps {
  readonly offer: OffersModel;
  readonly dealer?: DealerModel;
  readonly imageMap?: ImageOfferData;
  readonly opacity?: boolean;
  readonly trackingManager?: FeatureAppTrackingManager;
}

export const OfferCard: React.FC<OfferCardProps> = React.memo(
  (props): JSX.Element | null => {
    const {faHostServerUrl = ''} = useFeatureAppConfig();
    const {offer, opacity, dealer, trackingManager} = props;
    let {imageMap: imageMapProps} = props;
    const {[IncentiveConstants.SetModalFormData]: setModalFormData} = useStore(
      false
    )[1];
    const translations = useTranslations().tier2.landing.featuredOffers
      .offerCard;

    let imageMap: ImageOfferData = {
      modelImage: '',
      modelYear: '',
      isNewImage: false,
      background: '#ffffff'
    };
    const isNotStandardModel = typeof offer.modelCustomCta !== 'undefined';

    imageMapProps && (imageMap = imageMapProps);
    const setContactDealerFormData = () => {
      setModalFormData(
        {
          ...offer,
          mediaImageUrl:
            transformIrisUrl(
              imageMap.modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap.modelYear,
              offer.modelKey,
              imageMap.isNewImage
            ) || faHostServerUrl + AppConstants.Ngw6Logo960x432
        },
        dealer,
        AppConstants.ContactDealerFormType,
        AppConstants.ContactDealerFormName
      );
      trackingManager &&
        onHandleFormButtonTracking(
          offer,
          translations.contactDealer,
          trackingManager,
          undefined,
          undefined,
          dealer
        );
    };

    const setNotStandardModelData = () => {
      trackingManager &&
        onHandleFormButtonTracking(
          offer,
          offer.modelCustomCta?.ctaLabel,
          trackingManager,
          undefined,
          undefined,
          dealer
        );
    };

    const setGetAQuoteFormData = () => {
      setModalFormData(
        {
          ...offer,
          mediaImageUrl:
            transformIrisUrl(
              imageMap.modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap.modelYear,
              offer.modelKey,
              imageMap.isNewImage
            ) || faHostServerUrl + AppConstants.Ngw6Logo960x432
        },
        dealer,
        AppConstants.GetAQuoteFormType,
        AppConstants.GetAQuoteFormName
      );
      trackingManager &&
        onHandleFormButtonTracking(
          offer,
          translations.getAQuote,
          trackingManager,
          undefined,
          undefined,
          dealer
        );
    };

    const setDetailsFormData = () => {
      const offerForm = {
        ...offer,
        mediaImageUrl:
          transformIrisUrl(
            imageMap.modelImage,
            AppConstants.IrisImgPositionE13,
            '960',
            '432',
            imageMap.modelYear,
            offer.modelKey,
            imageMap.isNewImage
          ) || faHostServerUrl + AppConstants.Ngw6Logo960x432
      };
      if (isNotStandardModel) {
        setModalFormData(
          offerForm,
          dealer,
          AppConstants.NotStandardModelFormType,
          AppConstants.NotStandardModelFormName
        );
      } else {
        setModalFormData(
          offerForm,
          dealer,
          AppConstants.OfferDetailsFormType,
          AppConstants.OfferDetailsFormName
        );
      }
    };

    const offerTypeLabelInfo = getOfferTypeLabelInfo(offer.dealOfferTypes[0]);

    return (
      <StyledOfferCardWrapper>
        <StyledInformationWrapper>
          <StyledOpacityWrapper isOpacity={opacity} />
          <StyledBackground
            data-bg={imageMap.background}
            backgroundColor={imageMap.background}
          />
          <StyledTextWrapper
            headTextColor={getContrastColorText(imageMap.background)}
          >
            <TextCustom
              appearance={TextAppearance.headline200}
              style={{
                fontSize: TextSize.TextSize24,
                fontWeight: TextWeight.bold,
                color: TextColor.inherit,
                lineHeight: TextLineHeight.TextLineHeight29
              }}
            >
              {offer.modelKey &&
              offer.modelKey === AppConstants.AllOfferModelKey
                ? offer.modelDisplayName
                : `${offer.dealCarYear[0] || ''} ${offer.modelDisplayName ||
                    ''}`}
            </TextCustom>

            {offer.dealCarTrimName && offer.dealCarTrimName[0] && (
              <TextCustom
                appearance={TextAppearance.headline200}
                style={{
                  fontSize: TextSize.TextSize24,
                  fontWeight: TextWeight.regular,
                  color: TextColor.inherit,
                  lineHeight: TextLineHeight.TextLineHeight29
                }}
              >
                {offer.dealCarTrimName[0] || ''}
              </TextCustom>
            )}
          </StyledTextWrapper>
          <StyledImagePlaceholder>
            <StyledImageWrapper className="image-wrapper">
              <CustomImage
                alt={offer.modelDisplayName || ''}
                srcPlaceholder={AppConstants.PlaceHolder480x216 as string}
                srcErrorImage={faHostServerUrl + AppConstants.Ngw6Logo480x216}
                src={
                  transformIrisUrl(
                    imageMap.modelImage,
                    AppConstants.IrisImgPositionE13,
                    '480',
                    '216',
                    imageMap.modelYear,
                    offer.modelKey,
                    imageMap.isNewImage
                  ) || faHostServerUrl + AppConstants.Ngw6Logo480x216
                }
              />
            </StyledImageWrapper>
          </StyledImagePlaceholder>

          <StyledOfferTypeLabelWrapper>
            <StyledOfferTypeLabel
              backgroundColor={offerTypeLabelInfo.backgroundColor}
              textColor={offerTypeLabelInfo.textColor}
            >
              <TextCustom
                appearance={TextAppearance.label100}
                tag={TextTag.span}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight20,
                    color: TextColor.inherit,
                    fontWeight: TextWeight.bold
                  }
                }}
              >
                {offerTypeLabelInfo.label}
              </TextCustom>
            </StyledOfferTypeLabel>
          </StyledOfferTypeLabelWrapper>
          <StyledInformationOfferContainer>
            <StyledOfferTitle>
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.h2}
                style={{
                  [Breakpoints.default]: {
                    fontWeight: TextWeight.bold,
                    fontSize: TextSize.TextSize24,
                    lineHeight: TextLineHeight.TextLineHeight29
                  }
                }}
              >
                {clearText(offer.dealTitle)}
              </TextCustom>
            </StyledOfferTitle>
            <StyledOfferSubtitle>
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    fontWeight: TextWeight.regular
                  }
                }}
              >
                {offer.dealSubtitle}
              </TextCustom>
            </StyledOfferSubtitle>
            <StyledOfferMicroText>
              {offer.dealCopy && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight20,
                      fontWeight: TextWeight.regular,
                      color: TextColor.inherit
                    }
                  }}
                >
                  {offer.dealCopy}
                </TextCustom>
              )}
              {offer.dealAdditionalTerms && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular
                    }
                  }}
                >
                  {offer.dealAdditionalTerms}
                </TextCustom>
              )}
              {offer.dealExclusions && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular
                    }
                  }}
                >
                  {offer.dealExclusions}
                </TextCustom>
              )}
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    color: TextColor.inherit,
                    fontWeight: TextWeight.regular
                  }
                }}
              >
                Local Offer. Offer ends {getDateFormat(offer.dealEndDate)}
              </TextCustom>
            </StyledOfferMicroText>
            <StyledShowOfferDetails>
              <DisableFocusWrapper>
                <LinkButton className={'view-offers'} onClick={setDetailsFormData} isInline>
                  <TextCustom
                    appearance={TextAppearance.label100}
                    tag={TextTag.p}
                    style={{
                      [Breakpoints.default]: {
                        fontSize: TextSize.TextSize14,
                        lineHeight: TextLineHeight.TextLineHeight16,
                        color: TextColor.inherit,
                        fontWeight: TextWeight.bold
                      }
                    }}
                  >
                    {translations.offerDetails}
                  </TextCustom>
                </LinkButton>
              </DisableFocusWrapper>
            </StyledShowOfferDetails>
          </StyledInformationOfferContainer>
        </StyledInformationWrapper>
        <StyledSectionButton>
          <StyledButtonWrapper oneElement={isNotStandardModel}>
            {isNotStandardModel ? (
              <StyledButtonSection>
                <DisableFocusWrapper>
                  <ButtonCustomLink
                    appearance={ButtonAppearance.Primary}
                    onClick={setNotStandardModelData}
                    padding={buttonPaddingGenerator(0)}
                    href={offer.modelCustomCta?.url}
                    target={
                      offer.modelCustomCta?.openNewTab ? '_blank' : '_self'
                    }
                    className={'build-reserve'}
                  >
                    <Text
                      color={TextColor.inherit}
                      appearance={TextAppearance.label150}
                    >
                      {offer.modelCustomCta?.ctaLabel}
                    </Text>
                  </ButtonCustomLink>
                </DisableFocusWrapper>
              </StyledButtonSection>
            ) : (
              <>
                <StyledButtonSection>
                  <DisableFocusWrapper>
                    <ButtonCustom
                      appearance={ButtonAppearance.Secondary}
                      onClick={() => setGetAQuoteFormData()}
                      padding={buttonPaddingGenerator(0)}
                      className={'get-a-quote'}
                    >
                      <Text
                        color={TextColor.inherit}
                        appearance={TextAppearance.label150}
                      >
                        {translations.getAQuote}
                      </Text>
                    </ButtonCustom>
                  </DisableFocusWrapper>
                </StyledButtonSection>

                <StyledButtonSection>
                  <DisableFocusWrapper>
                    <ButtonCustom
                      appearance={ButtonAppearance.Primary}
                      onClick={setContactDealerFormData}
                      padding={buttonPaddingGenerator(0)}
                      className={'contact-dealer'}
                    >
                      <Text
                        color={TextColor.inherit}
                        appearance={TextAppearance.label150}
                      >
                        {translations.contactDealer}
                      </Text>
                    </ButtonCustom>
                  </DisableFocusWrapper>
                </StyledButtonSection>
              </>
            )}
          </StyledButtonWrapper>
        </StyledSectionButton>
      </StyledOfferCardWrapper>
    );
  }
);
