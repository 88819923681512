import {LocaleServiceV1} from '@volkswagen-onehub/locale-service';
import {IpToZip, ZipManagerV1, ZipResponse} from '@volkswagen-onehub/zip-manager';
import {History} from 'history';
import isBrowser from 'is-browser';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import {useHistory} from 'react-router';
import {MappedTrimNameModel} from '../../config/mapped-trim-name';
import ErrorCodes from '../../error-handling/error-codes';
import ErrorMessages from '../../error-handling/error-mesagges';
import IncentiveConstants from '../../hooks-store/incentive-store/actions/incentive-constants';
import {useStore} from '../../hooks-store/store';
import {
  AuthoredLmaDetailModel,
  DealerModel,
  ErrorHandlingModel,
  LocationModel,
  OfferInformation,
  OffersModel,
  TypeMap,
  IncentiveStoreModel
} from '../../hooks-store/typings/incentive-store';
import {StoreModel} from '../../hooks-store/typings/store';
import {getStringifiedServiceConfigsServiceConfig} from '../../services/get-stringified-service-configs-service-config';
import {useFeatureServices} from '../../services/use-feature-services';
import {useLogger} from '../../services/use-logger';
import {useUniversalEffect} from '../../services/use-universal-effect';
import {AppConstants} from '../../utils/app-constants';
import {getTestMode} from '../../utils/navigation-url';
import {fetchLocation} from './fetch-location';
import {
  getAorDealer,
  getAuthoredLmaDetail,
  getDealPriority,
  mapOffersData,
  transformData
} from './format';
import {fetchModelConfig} from '../fetch-model-config';
import {ModelOrder} from '../../config/tier-1/model-order';
import {ModelConfig} from '../../typings/model-config';
import {FeatureAppTrackingManager} from '../../context/use-tracking-manager';
import {onHandlerFilterSeachLoad} from '../../utils/tagging-helpers';
import { fetchDealersLmaByVanityUrl } from '../graphql/fetch-dealer-lma-by-vanity-url';
import { fetchDealersLmaByZipCode } from '../graphql/fetch-dealer-lma-by-zipcode';
import { fetchOffersByLmaId } from '../graphql/fetch-offers-by-lma-id';
import { ServiceConfig } from '../graphql/typing';

interface FetchOptions {
  readonly ipToZip?: IpToZip;
  readonly lmaVanityUrl?: string;
  readonly zip: string;
  readonly localeService: LocaleServiceV1;
  readonly trackingManager?: FeatureAppTrackingManager;
  readonly serviceConfigsServiceConfig: string;
  readonly zipManager?: ZipManagerV1;
  readonly history: History;
  readonly faServicesUrl: string;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
  readonly mappedTrimName?: MappedTrimNameModel;
  readonly generalmodelOrder?: ModelOrder[];
  readonly offerId?: number;
  readonly modelsConfig: ModelConfig | undefined;
  readonly updateStoreByZip?: (
    offersData: TypeMap<OfferInformation>,
    serviceOffersData: OffersModel[],
    dealers: DealerModel[],
    dealer: DealerModel | null,
    authoredLmaDetail: AuthoredLmaDetailModel | undefined,
    fetchedLocation: LocationModel,
    history: History,
    zip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined,
    offerId?: number
  ) => StoreModel;
  readonly setInitialData?: (
    offersData: TypeMap<OfferInformation>,
    serviceOffersData: OffersModel[],
    dealers: DealerModel[],
    dealer: DealerModel | null,
    authoredLmaDetail: AuthoredLmaDetailModel | undefined,
    fetchedLocation: LocationModel,
    history: History,
    zip: string,
    ipZip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined
  ) => StoreModel;
  readonly successResultCallback?: (store: IncentiveStoreModel) => void;
  readonly graphQLServer?: ServiceConfig
}

const getDealersError = (errorHandling: ErrorHandlingModel[]) =>
  uniqBy(
    [
      {
        errorCode: ErrorCodes.FetchDealersError,
        errorMessage: ErrorMessages.FetchDealersError
      },
      ...errorHandling
    ],
    'errorCode'
  );

const getLocationError = (errorHandling: ErrorHandlingModel[]) =>
  uniqBy(
    [
      {
        errorCode: ErrorCodes.FetchLocationError,
        errorMessage: ErrorMessages.FetchLocationError
      },
      ...errorHandling
    ],
    'errorCode'
  );
const getOffersError = (errorHandling: ErrorHandlingModel[]) =>
  uniqBy(
    [
      {
        errorCode: ErrorCodes.FetchOffersError,
        errorMessage: ErrorMessages.FetchOffersError
      },
      ...errorHandling
    ],
    'errorCode'
  );
const getServiceOffersError = (errorHandling: ErrorHandlingModel[]) =>
  uniqBy(
    [
      {
        errorCode: ErrorCodes.FetchServiceOffersError,
        errorMessage: ErrorMessages.FetchServiceOffersError
      },
      ...errorHandling
    ],
    'errorCode'
  );

async function validateZipCodeByDealers({
  zip,
  graphQLServer
}: any) {
  try {
    const dealersResults:any = await fetchDealersLmaByZipCode({
      zipCode: zip,
      graphQLServer
    });

    if (dealersResults.errorStatus || !dealersResults.data.dealers.length) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
}

/**
 * Fetch all the necessary data from SSR to start the app
 * @param lmaVanityUrl LMA vanity url
 * @param faServicesUrl FA service url
 * @param mockIds Mock id
 * @param mocksBaseUrl Mock base url
 * @param mappedTrimName Obj form cms configuration to map the trim name
 */
export const fetchInitialDataSSR = (
  lmaVanityUrl: string,
  faServicesUrl: string,
  mockIds?: string,
  mocksBaseUrl?: string,
  mappedTrimName?: MappedTrimNameModel,
  generalModelOrder?: ModelOrder[]
): StoreModel | undefined => {
  // Call different services used to apply fetches and update the store data
  const logger = useLogger();
  const history = useHistory();

  const {
    's2:async-ssr-manager': asyncSsrManager,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
    'zip-manager': zipManager
  } = useFeatureServices();

  const {
    configs: { iptozip: ipToZip,"graphql-server": graphQLServer },
  } = serviceConfigProvider;
  
  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest
  );

  // App store
  const [
    store,
    {[IncentiveConstants.SetInitialDataTier2]: setInitialData}
  ] = useStore(false);

  let zipAux = '33040';

  // Custom useEffect
  useUniversalEffect(() => {
    if (store && store.status) {
      if (!isBrowser && store.status === AppConstants.StoreStatusLoaded) {
        return;
      }

      if (store.status === AppConstants.StoreStatusFailed && store.ssr) {
        logger.warn('Offers SSR error detected, trying to fetch data again.');
      } else {
        return;
      }
    }


    let fetchedDealers: DealerModel[];
    let fetchedDealer: DealerModel | null;
    let fetchedOffers: TypeMap<OfferInformation>;
    let fetchedLocation: LocationModel;
    let fetchedServiceOffers: OffersModel[];
    let lmaId = '';
    let authoredLmaDetail: AuthoredLmaDetailModel | undefined;
    let errorHandling: ErrorHandlingModel[] = [];

    if (asyncSsrManager) {
      asyncSsrManager.scheduleRerender(
        Promise.resolve(
          fetchModelConfig({
            serviceConfigsServiceConfig,
            mockIds,
            mocksBaseUrl,
            faServicesUrl
          })
            .then(result => {
              const modelsConfig = result;
              if (!lmaVanityUrl) {
                // Get zip from IP
                asyncSsrManager.scheduleRerender(
                  Promise.resolve(
                    zipManager.getZipByIp(true, ipToZip as IpToZip)
                  ).then(
                    zipIpResp => {
                      zipAux = zipIpResp.status
                        ? (zipIpResp as ZipResponse).response
                        : '33040'; // set a default since the iptozip call failed and we need a zip to load

                      asyncSsrManager.scheduleRerender(
                        Promise.all([
                          validateZipCodeByDealers({
                            zip: zipAux,
                            graphQLServer
                          }).then((result: any) => {
                            if (!result) {
                              zipAux = '90210';
                            }
                            asyncSsrManager.scheduleRerender(
                              Promise.all([
                                fetchDealersLmaByZipCode({
                                  zipCode: zipAux,
                                  graphQLServer
                                })
                                  .then((result: any) => {
                                    if (result.errorStatus) {
                                      fetchedDealers = [];
                                      fetchedDealer = null;
                                      authoredLmaDetail = getAuthoredLmaDetail(
                                        ''
                                      );
                                    } else {
                                      fetchedDealers = transformData(
                                        result.data.dealers
                                      );
                                      fetchedDealer = getAorDealer(
                                        fetchedDealers
                                      );
                                      lmaId = fetchedDealer?.lmaId || '';
                                      authoredLmaDetail = getAuthoredLmaDetail(
                                        lmaId
                                      );
                                    }
                                    if (result.errorStatus) {
                                      errorHandling = getDealersError(
                                        errorHandling
                                      );
                                    }
                                  })
                                  .catch((error: Error) => {
                                    fetchedDealers = [];
                                    fetchedDealer = null;
                                    authoredLmaDetail = getAuthoredLmaDetail(
                                      ''
                                    );
                                    errorHandling = getDealersError(
                                      errorHandling
                                    );
                                    logger.error(error);
                                  })
                              ]).then(() => {
                                asyncSsrManager.scheduleRerender(
                                  Promise.all([
                                    fetchOffersByLmaId({
                                      lmaId,
                                      graphQLServer
                                    })
                                      .then((result: any) => {
                                        if (result.errorStatus) {
                                          fetchedOffers = {};
                                        } else {
                                          fetchedOffers = mapOffersData(
                                            result.data,
                                            lmaId,
                                            mappedTrimName,
                                            modelsConfig,
                                            generalModelOrder
                                          );
                                        }
                                        if (result.errorStatus) {
                                          errorHandling = getOffersError(
                                            errorHandling
                                          );
                                        }
                                      })
                                      .catch((error: Error) => {
                                        fetchedOffers = {};
                                        errorHandling = getOffersError(
                                          errorHandling
                                        );
                                        logger.error(error);
                                      }),
                                      fetchOffersByLmaId({
                                      lmaId,
                                      offerType: "service",
                                      graphQLServer
                                    })
                                      .then((result: any) => {
                                        result.errorStatus
                                          ? (fetchedServiceOffers = [])
                                          : (fetchedServiceOffers = getDealPriority(
                                              result.data
                                            ));

                                        if (result.errorStatus) {
                                          errorHandling = getServiceOffersError(
                                            errorHandling
                                          );
                                        }
                                      })
                                      .catch((error: Error) => {
                                        fetchedServiceOffers = [];
                                        errorHandling = getServiceOffersError(
                                          errorHandling
                                        );
                                        logger.error(error);
                                      }),
                                    fetchLocation(zipAux)
                                      .then((result: any) => {
                                        if (result.errorStatus) {
                                          fetchedLocation = {
                                            zip: '',
                                            lat: 0,
                                            lng: 0,
                                            error: true
                                          };
                                        } else {
                                          const location =
                                            result.data.results[0].geometry
                                              .location;
                                          fetchedLocation = {
                                            zip: zipAux,
                                            lat: location.lat,
                                            lng: location.lng,
                                            error: false
                                          };
                                        }
                                        if (result.errorStatus) {
                                          errorHandling = getLocationError(
                                            errorHandling
                                          );
                                        }
                                      })
                                      .catch((error: Error) => {
                                        fetchedLocation = {
                                          zip: '',
                                          lat: 0,
                                          lng: 0,
                                          error: true
                                        };
                                        errorHandling = getLocationError(
                                          errorHandling
                                        );
                                        logger.error(error);
                                      })
                                  ]).then(() => {
                                    setInitialData(
                                      fetchedOffers,
                                      fetchedServiceOffers,
                                      fetchedDealers,
                                      fetchedDealer,
                                      authoredLmaDetail,
                                      fetchedLocation,
                                      history,
                                      zipAux,
                                      zipAux,
                                      errorHandling,
                                      modelsConfig
                                    );
                                  })
                                );
                              })
                            );
                          })
                        ])
                      );
                    },
                    value => {
                      console.log('Errors fetching ipzip:' + value);
                      console.log('Using default value: 33040');
                      zipAux = '33040';

                      asyncSsrManager.scheduleRerender(
                        Promise.all([
                          fetchDealersLmaByZipCode({
                            zipCode: zipAux,
                            graphQLServer
                          })
                            .then((result: any) => {
                              if (result.errorStatus) {
                                fetchedDealers = [];
                                fetchedDealer = null;
                                authoredLmaDetail = getAuthoredLmaDetail('');
                              } else {
                                fetchedDealers = transformData(
                                  result.data.dealers
                                );
                                fetchedDealer = getAorDealer(fetchedDealers);
                                lmaId = fetchedDealer?.lmaId || '';
                                authoredLmaDetail = getAuthoredLmaDetail(lmaId);
                              }
                              if (result.errorStatus) {
                                errorHandling = getDealersError(errorHandling);
                              }
                            })
                            .catch((error: Error) => {
                              fetchedDealers = [];
                              fetchedDealer = null;
                              authoredLmaDetail = getAuthoredLmaDetail('');
                              errorHandling = getDealersError(errorHandling);
                              logger.error(error);
                            })
                        ]).then(() => {
                          asyncSsrManager.scheduleRerender(
                            Promise.all([
                              fetchOffersByLmaId({
                                lmaId,
                                graphQLServer
                              })
                                .then((result: any) => {
                                  if (result.errorStatus) {
                                    fetchedOffers = {};
                                  } else {
                                    fetchedOffers = mapOffersData(
                                      result.data,
                                      lmaId,
                                      mappedTrimName,
                                      modelsConfig,
                                      generalModelOrder
                                    );
                                  }
                                  if (result.errorStatus) {
                                    errorHandling = getOffersError(
                                      errorHandling
                                    );
                                  }
                                })
                                .catch((error: Error) => {
                                  fetchedOffers = {};
                                  errorHandling = getOffersError(errorHandling);
                                  logger.error(error);
                                }),
                              fetchOffersByLmaId({
                                lmaId,
                                offerType: "service",
                                graphQLServer
                              })
                                .then((result: any) => {
                                  result.errorStatus
                                    ? (fetchedServiceOffers = [])
                                    : (fetchedServiceOffers = getDealPriority(
                                        result.data
                                      ));

                                  if (result.errorStatus) {
                                    errorHandling = getServiceOffersError(
                                      errorHandling
                                    );
                                  }
                                })
                                .catch((error: Error) => {
                                  fetchedServiceOffers = [];
                                  errorHandling = getServiceOffersError(
                                    errorHandling
                                  );
                                  logger.error(error);
                                }),
                              fetchLocation(zipAux)
                                .then((result: any) => {
                                  if (result.errorStatus) {
                                    fetchedLocation = {
                                      zip: '',
                                      lat: 0,
                                      lng: 0,
                                      error: true
                                    };
                                  } else {
                                    const location =
                                      result.data.results[0].geometry.location;
                                    fetchedLocation = {
                                      zip: zipAux,
                                      lat: location.lat,
                                      lng: location.lng,
                                      error: false
                                    };
                                  }
                                  if (result.errorStatus) {
                                    errorHandling = getLocationError(
                                      errorHandling
                                    );
                                  }
                                })
                                .catch((error: Error) => {
                                  fetchedLocation = {
                                    zip: '',
                                    lat: 0,
                                    lng: 0,
                                    error: true
                                  };
                                  errorHandling = getLocationError(
                                    errorHandling
                                  );
                                  logger.error(error);
                                })
                            ]).then(() => {
                              setInitialData(
                                fetchedOffers,
                                fetchedServiceOffers,
                                fetchedDealers,
                                fetchedDealer,
                                authoredLmaDetail,
                                fetchedLocation,
                                history,
                                zipAux,
                                zipAux,
                                errorHandling,
                                modelsConfig
                              );
                            })
                          );
                        })
                      );
                    }
                  )
                );
              } else {
                asyncSsrManager.scheduleRerender(
                  Promise.all([
                    validateZipCodeByDealers({
                      zip: zipAux,
                      graphQLServer
                    }).then((result: any) => {
                      if (!result) {
                        zipAux = '90210';
                      }
                      const fetchedDealerRequest = lmaVanityUrl
                        ? fetchDealersLmaByVanityUrl({
                            vanityUrl: lmaVanityUrl,
                            graphQLServer
                          })
                            .then((result: any) => {
                              if (result.errorStatus) {
                                fetchedDealers = [];
                                fetchedDealer = null;
                                authoredLmaDetail = getAuthoredLmaDetail('');
                              } else {
                                fetchedDealers = transformData(
                                  result.data.dealers
                                );
                                fetchedDealer = getAorDealer(fetchedDealers);
                                lmaId = fetchedDealer?.lmaId || '';
                                zipAux = fetchedDealer?.postalcode || '';
                                authoredLmaDetail = getAuthoredLmaDetail(lmaId);
                              }
                              if (result.errorStatus) {
                                errorHandling = getDealersError(errorHandling);
                              }
                            })
                            .catch((error: Error) => {
                              fetchedDealers = [];
                              fetchedDealer = null;
                              authoredLmaDetail = getAuthoredLmaDetail('');
                              errorHandling = getDealersError(errorHandling);
                              console.log('ERROR:: fetchDealersByLma ', error);
                            })
                        : fetchDealersLmaByZipCode({
                            zipCode: zipAux,
                            graphQLServer,
                          })
                            .then((result: any) => {
                              if (result.errorStatus) {
                                fetchedDealers = [];
                                fetchedDealer = null;
                                authoredLmaDetail = getAuthoredLmaDetail('');
                              } else {
                                fetchedDealers = transformData(
                                  result.data.dealers
                                );
                                fetchedDealer = getAorDealer(fetchedDealers);
                                lmaId = fetchedDealer?.lmaId || '';
                                authoredLmaDetail = getAuthoredLmaDetail(lmaId);
                              }
                              if (result.errorStatus) {
                                errorHandling = getDealersError(errorHandling);
                              }
                            })
                            .catch((error: Error) => {
                              fetchedDealers = [];
                              fetchedDealer = null;
                              authoredLmaDetail = getAuthoredLmaDetail('');
                              errorHandling = getDealersError(errorHandling);
                              console.log('ERROR:: fetchDealers ', error);
                            });
                      asyncSsrManager.scheduleRerender(
                        Promise.all([fetchedDealerRequest]).then(() => {
                          asyncSsrManager.scheduleRerender(
                            Promise.all([
                              fetchOffersByLmaId({
                                lmaId,
                                graphQLServer
                              })
                                .then((result: any) => {
                                  if (result.errorStatus) {
                                    fetchedOffers = {};
                                  } else {
                                    fetchedOffers = mapOffersData(
                                      result.data,
                                      lmaId,
                                      mappedTrimName,
                                      modelsConfig,
                                      generalModelOrder
                                    );
                                  }
                                  if (result.errorStatus) {
                                    errorHandling = getOffersError(
                                      errorHandling
                                    );
                                  }
                                })
                                .catch((error: Error) => {
                                  fetchedOffers = {};
                                  errorHandling = getOffersError(errorHandling);
                                  logger.error(error);
                                }),
                              fetchOffersByLmaId({
                                offerType: "service",
                                lmaId,
                                graphQLServer
                              })
                                .then((result: any) => {
                                  result.errorStatus
                                    ? (fetchedServiceOffers = [])
                                    : (fetchedServiceOffers = getDealPriority(
                                        result.data
                                      ));

                                  if (result.errorStatus) {
                                    errorHandling = getServiceOffersError(
                                      errorHandling
                                    );
                                  }
                                })
                                .catch((error: Error) => {
                                  fetchedServiceOffers = [];
                                  errorHandling = getServiceOffersError(
                                    errorHandling
                                  );
                                  logger.error(error);
                                }),
                              fetchLocation(zipAux)
                                .then((result: any) => {
                                  if (result.errorStatus) {
                                    fetchedLocation = {
                                      zip: '',
                                      lat: 0,
                                      lng: 0,
                                      error: true
                                    };
                                  } else {
                                    const location =
                                      result.data.results[0].geometry.location;
                                    fetchedLocation = {
                                      zip: zipAux,
                                      lat: location.lat,
                                      lng: location.lng,
                                      error: false
                                    };
                                  }
                                  if (result.errorStatus) {
                                    errorHandling = getLocationError(
                                      errorHandling
                                    );
                                  }
                                })
                                .catch((error: Error) => {
                                  fetchedLocation = {
                                    zip: '',
                                    lat: 0,
                                    lng: 0,
                                    error: true
                                  };
                                  errorHandling = getLocationError(
                                    errorHandling
                                  );
                                  logger.error(error);
                                })
                            ]).then(() => {
                              setInitialData(
                                fetchedOffers,
                                fetchedServiceOffers,
                                fetchedDealers,
                                fetchedDealer,
                                authoredLmaDetail,
                                fetchedLocation,
                                history,
                                zipAux,
                                '',
                                errorHandling,
                                modelsConfig
                              );
                            })
                          );
                        })
                      );
                    })
                  ])
                );
              }
            })
            .catch(error => {
              logger.debug(error);
            })
        )
      );
    }
  }, []);

  return store?.status === AppConstants.StoreStatusLoaded ? store : undefined;
};

/**
 * Fetch all the necessary data from CSR to start the app
 * @param lmaVanityUrl LMA vanity URL
 * @param zip Postal Code
 * @param localeService Gobal service
 * @param serviceConfigsServiceConfig Global service
 * @param zipManager Zip service
 * @param setInitialData Store function to set data
 * @param history Router history
 * @param faServicesUrl FA service url
 * @param mockIds Mock id
 * @param mocksBaseUrl Mock base url
 * @param modelOrder Obj form cms configuration to sort the offers categories
 * @param mappedTrimName Obj form cms configuration to map the trim name
 */
export async function fetchInitialDataCSR({
  lmaVanityUrl,
  zip,
  serviceConfigsServiceConfig,
  zipManager,
  setInitialData,
  history,
  faServicesUrl,
  mockIds,
  mocksBaseUrl,
  mappedTrimName,
  generalmodelOrder,
  trackingManager,
  modelsConfig,
  ipToZip,
  graphQLServer
}: FetchOptions): Promise<any> {
  let zipAux = zip;

  let fetchedDealers: DealerModel[];
  let fetchedDealer: DealerModel | null;
  let fetchedOffers: TypeMap<OfferInformation>;
  let fetchedLocation: LocationModel;
  let fetchedServiceOffers: OffersModel[];
  let lmaId = '';
  let authoredLmaDetail: AuthoredLmaDetailModel | undefined;
  let errorHandling: ErrorHandlingModel[] = [];

  if (!lmaVanityUrl && isEmpty(zip)) {
    if (zipManager) {
      try {
        const zipResponse = await zipManager.getZipByIp(true, ipToZip as IpToZip)
        zipAux = zipResponse.status
          ? (zipResponse as ZipResponse).response
          : '33040'; // set a default since the iptozip call failed and we need a zip to load
      } catch (error) {
        zipAux = '33040';
        console.log('Error: zipManager request', error);
      }
    } else {
      zipAux = '33040';
    }
  }

  modelsConfig =
    modelsConfig ||
    (await fetchModelConfig({
      faServicesUrl,
      serviceConfigsServiceConfig,
      mockIds,
      mocksBaseUrl
    }));

  const fetchedDealerRequest =
    lmaVanityUrl && isEmpty(zip)
      ? fetchDealersLmaByVanityUrl({
          vanityUrl: lmaVanityUrl,
          graphQLServer
        })
          .then((result: any) => {
            if (result.errorStatus) {
              fetchedDealers = [];
              fetchedDealer = null;
              authoredLmaDetail = getAuthoredLmaDetail('');
            } else {
              fetchedDealers = transformData(result.data.dealers);
              fetchedDealer = getAorDealer(fetchedDealers);
              lmaId = fetchedDealer?.lmaId || '';
              zipAux = fetchedDealer?.postalcode || '';
              authoredLmaDetail = getAuthoredLmaDetail(lmaId);
            }
            if (result.errorStatus) {
              errorHandling = getDealersError(errorHandling);
            }
          })
          .catch((error: Error) => {
            fetchedDealers = [];
            fetchedDealer = null;
            authoredLmaDetail = getAuthoredLmaDetail('');
            errorHandling = getDealersError(errorHandling);
            console.log('ERROR:: fetchDealersByLma ', error);
          })
      : fetchDealersLmaByZipCode({
          zipCode: zipAux,
          graphQLServer
        })
          .then((result: any) => {
            if (result.errorStatus) {
              fetchedDealers = [];
              fetchedDealer = null;
              authoredLmaDetail = getAuthoredLmaDetail('');
            } else {
              fetchedDealers = transformData(result.data.dealers);
              fetchedDealer = getAorDealer(fetchedDealers);
              lmaId = fetchedDealer?.lmaId || '';
              authoredLmaDetail = getAuthoredLmaDetail(lmaId);
            }
            if (result.errorStatus) {
              errorHandling = getDealersError(errorHandling);
            }
          })
          .catch((error: Error) => {
            fetchedDealers = [];
            fetchedDealer = null;
            authoredLmaDetail = getAuthoredLmaDetail('');
            errorHandling = getDealersError(errorHandling);
            console.log('ERROR:: fetchDealers ', error);
          });

  Promise.all([fetchedDealerRequest]).then(() => {
    Promise.all([
      fetchOffersByLmaId({
        lmaId,
        graphQLServer
      })
        .then((result: any) => {
          if (result.errorStatus) {
            fetchedOffers = {};
          } else {
            fetchedOffers = mapOffersData(
              result.data,
              lmaId,
              mappedTrimName,
              modelsConfig,
              generalmodelOrder
            );
          }
          if (result.errorStatus) {
            errorHandling = getOffersError(errorHandling);
          }
        })
        .catch((error: Error) => {
          fetchedOffers = {};
          errorHandling = getOffersError(errorHandling);
          console.log('ERROR:: fetchOffers ', error);
        }),
      fetchOffersByLmaId({
        lmaId,
        offerType: "service",
        graphQLServer
      })
        .then((result: any) => {
          result.errorStatus
            ? (fetchedServiceOffers = [])
            : (fetchedServiceOffers = getDealPriority(result.data));

          if (result.errorStatus) {
            errorHandling = getServiceOffersError(errorHandling);
          }
        })
        .catch((error: Error) => {
          fetchedServiceOffers = [];
          errorHandling = getServiceOffersError(errorHandling);
          console.log('ERROR:: fetchServicelOffers ', error);
        }),
      fetchLocation(zipAux)
        .then((result: any) => {
          if (result.errorStatus) {
            fetchedLocation = {
              zip: '',
              lat: 0,
              lng: 0,
              error: true
            };
          } else {
            const location = result.data.results[0].geometry.location;
            fetchedLocation = {
              zip: zipAux,
              lat: location.lat,
              lng: location.lng,
              error: false
            };
          }
          if (result.errorStatus) {
            errorHandling = getLocationError(errorHandling);
          }
        })
        .catch((error: Error) => {
          fetchedLocation = {
            zip: '',
            lat: 0,
            lng: 0,
            error: true
          };
          errorHandling = getLocationError(errorHandling);
          console.log('ERROR:: fetchLocation', error);
        })
    ]).then(() => {
      if (setInitialData) {
        const updatedStore = setInitialData(
          fetchedOffers,
          fetchedServiceOffers,
          fetchedDealers,
          fetchedDealer,
          authoredLmaDetail,
          fetchedLocation,
          history,
          zipAux,
          '',
          errorHandling,
          modelsConfig
        );

        // Tracking when page was loaded.
        trackingManager &&
          onHandlerFilterSeachLoad(trackingManager, updatedStore);
      }
    });
  });

  return null;
}

/**
 * Update the app data after changing the zip code
 * @param zip Postal Code
 * @param localeService Gobal service
 * @param serviceConfigsServiceConfig Global service
 * @param updateStoreByZip Store function to set data
 * @param zipManager Zip service
 * @param history Router history
 * @param faServicesUrl FA service url
 * @param mockIds Mock id
 * @param mocksBaseUrl Mock base url
 * @param mappedTrimName Obj form cms configuration to map the trim name
 */
export async function updateStoreDataTier2({
  zip,
  serviceConfigsServiceConfig,
  updateStoreByZip,
  history,
  faServicesUrl,
  mockIds,
  mocksBaseUrl,
  mappedTrimName,
  generalmodelOrder,
  successResultCallback,
  offerId,
  modelsConfig,
  graphQLServer
}: FetchOptions): Promise<any> {
  const testMode = getTestMode(history);

  let fetchedDealers: DealerModel[];
  let fetchedDealer: DealerModel | null;
  let fetchedOffers: TypeMap<OfferInformation>;
  let fetchedLocation: LocationModel;
  let fetchedServiceOffers: OffersModel[];
  let lmaId = '';
  let authoredLmaDetail: AuthoredLmaDetailModel | undefined;
  let errorHandling: ErrorHandlingModel[] = [];
  let zipAux = zip;

  modelsConfig =
    modelsConfig ||
    (await fetchModelConfig({
      faServicesUrl,
      serviceConfigsServiceConfig,
      mockIds,
      mocksBaseUrl
    }));

  Promise.all([
    fetchDealersLmaByZipCode({
      zipCode: zipAux,
      graphQLServer
    })
      .then((result: any) => {
        if (result.errorStatus) {
          fetchedDealers = [];
          fetchedDealer = null;
          authoredLmaDetail = getAuthoredLmaDetail('');
        } else {
          fetchedDealers = transformData(result.data.dealers);
          fetchedDealer = getAorDealer(fetchedDealers);
          lmaId = fetchedDealer?.lmaId || '';
          authoredLmaDetail = getAuthoredLmaDetail(lmaId);
        }
        if (result.errorStatus) {
          errorHandling = getDealersError(errorHandling);
        }
      })
      .catch((error: Error) => {
        fetchedDealers = [];
        fetchedDealer = null;
        authoredLmaDetail = getAuthoredLmaDetail('');
        errorHandling = getDealersError(errorHandling);
        console.log('ERROR:: fetchDealers ', error);
      })
  ]).then(() => {
    Promise.all([
      fetchOffersByLmaId({
        lmaId,
        graphQLServer
      })
        .then((result: any) => {
          if (result.errorStatus) {
            fetchedOffers = {};
          } else {
            fetchedOffers =
              testMode === AppConstants.HideFeaturedOffers
                ? {}
                : mapOffersData(
                    result.data,
                    lmaId,
                    mappedTrimName,
                    modelsConfig,
                    generalmodelOrder
                  );
          }
          if (result.errorStatus) {
            errorHandling = getOffersError(errorHandling);
          }
        })
        .catch((error: Error) => {
          fetchedOffers = {};
          errorHandling = getOffersError(errorHandling);
          console.log('ERROR:: fetchOffers ', error);
        }),
      fetchOffersByLmaId({
        lmaId,
       graphQLServer,
       offerType: "service"
      })
        .then((result: any) => {
          result.errorStatus
            ? (fetchedServiceOffers = [])
            : (fetchedServiceOffers = getDealPriority(result.data));

          if (result.errorStatus) {
            errorHandling = getServiceOffersError(errorHandling);
          }
        })
        .catch((error: Error) => {
          fetchedServiceOffers = [];
          errorHandling = getServiceOffersError(errorHandling);
          console.log('ERROR:: fetchServicelOffers ', error);
        }),
      fetchLocation(zipAux)
        .then((result: any) => {
          if (result.errorStatus) {
            fetchedLocation = {
              zip: '',
              lat: 0,
              lng: 0,
              error: true
            };
          } else {
            const location = result.data.results[0].geometry.location;
            fetchedLocation = {
              zip: zipAux,
              lat: location.lat,
              lng: location.lng,
              error: false
            };
          }
          if (result.errorStatus) {
            errorHandling = getLocationError(errorHandling);
          }
        })
        .catch((error: Error) => {
          fetchedLocation = {
            zip: '',
            lat: 0,
            lng: 0,
            error: true
          };
          errorHandling = getLocationError(errorHandling);
          console.log('ERROR:: fetchLocation', error);
        })
    ]).then(() => {
      if (updateStoreByZip) {
        // zipManager?.setZipCookie(zip as string);
        const updatedStore = updateStoreByZip(
          fetchedOffers,
          fetchedServiceOffers,
          fetchedDealers,
          fetchedDealer,
          authoredLmaDetail,
          fetchedLocation,
          history,
          zipAux,
          errorHandling,
          modelsConfig,
          offerId
        );
        successResultCallback && successResultCallback(updatedStore);
      }
    });
  });

  return null;
}
