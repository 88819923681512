import {History} from 'history';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import {OfferTypeModel, localOfferType} from '../../../config/offer-type';
import {AppConstants} from '../../../utils/app-constants';
import {
  AttributeModel,
  FilterAttributeModel,
  OfferInformation,
  OffersModel,
  TypeMap
} from '../../typings/incentive-store';
import {urlToFilters} from './query';
import { SpecialEventConfig } from '../../..';

/**
 * Build an array to show the filters options in the filter sidebar
 * @param offersData all the offers form the feed
 */
export const buildFilterAttributes = (
  offersData: TypeMap<OfferInformation>,
  specialEvent?: SpecialEventConfig
): FilterAttributeModel[] => [
  {
    name: 'Offers',
    filterKey: 'offers',
    attributes: sortBy(getOffersAttributes(localOfferType, 'offers',specialEvent), 'text')
  },
  {
    name: 'Model',
    filterKey: 'model',
    attributes: sortBy(getModelAttributes(offersData, 'model'), 'text')
  }
];

/**
 * Format for the model attributes
 * @param offersData all offers from feed
 * @param filterKey Category key for models
 */
export const getModelAttributes = (
  offersData: TypeMap<OfferInformation>,
  filterKey: string
): AttributeModel[] => {
  const modelOfferMapKeys = Object.keys(offersData);

  return modelOfferMapKeys.map((key: string) => ({
    filterKey,
    text: offersData[key].modelDisplayName,
    value: offersData[key].modelDisplayName.toLocaleLowerCase()
  }));
};

/**
 * Format for the offer type attributes
 * @param offerTypeAttr Offer type form local configuration
 * @param filterKey Category key for offer type
 */
export const getOffersAttributes = (
  offerTypeAttr: OfferTypeModel[],
  filterKey: string,
  specialEvent?: SpecialEventConfig
): AttributeModel[] =>{
  const attributes =  offerTypeAttr.map((attr: OfferTypeModel) => ({
    filterKey,
    text: attr.offerName,
    value: attr.offerType,
    isSpecialEvent: false
  }));
  if(specialEvent?.filterOptions?.length){
    specialEvent?.filterOptions.map(({text = "", value = "",isActive}) => {
      if (isActive) {
        attributes.push({text, value, filterKey: "special-event",
          isSpecialEvent: true})
      }
    })
  }
  return attributes
}
  

/**
 * Get the offer type category
 * @param offerTypeKey Is the offer type name
 */
export const getOfferType = (offerType: string) => {
  const replacements: any = {
    apr: AppConstants.OfferTypeAPR,
    lease: AppConstants.OfferTypeLease
  };
  if (replacements.hasOwnProperty(offerType)) {
    return replacements[offerType];
  }

  return AppConstants.OfferTypeOther;
};

/**
 * Get a list of offers filtered by the currently filters selected
 * @param modelOfferMap All offers from feed
 * @param appliedFilters Current apply filters
 */
export const filterOffers = (
  modelOfferMap: TypeMap<OfferInformation>,
  appliedFilters: AttributeModel[]
): OfferInformation[] => {
  // Create an array of offers without the model key
  const modelsOffers = Object.keys(modelOfferMap).map(
    modelKey => modelOfferMap[modelKey]
  );

  // if there is not applied filters return model offers
  if (appliedFilters.length === 0) {
    return modelsOffers;
  }

  // Create a hash with the filterKey (filterKey: [value, value])
  const filters = appliedFilters.reduce(
    (next: any, {filterKey, value}: any) => ({
      ...next,
      [filterKey]: [...(next[filterKey] || []), value.toLowerCase()]
    }),
    {}
  );
  // lodash get function to get an array of values
  const dealCarModelName = get(filters, 'model', []).map((item: any) =>
    item.toLowerCase()
  );
  // lodash get function to get an array of values
  const dealOfferTypes = get(filters, 'offers', []).map((item: any) =>
    item.toLowerCase()
  );

  const specialEvents = get(filters, 'special-event', []).map((item: any) =>
  item.toLowerCase()
);
  // model filter selected
  let filteredByModel = modelsOffers.filter(model => {
    if (dealCarModelName.length) {
      return dealCarModelName.includes(model.modelDisplayName.toLowerCase());
    } 
    return true;
  });
  if (specialEvents.length && !dealCarModelName.length) {
    filteredByModel = filteredByModel.filter(model => {
      return specialEvents.includes(model.modelTag?.label.toLocaleLowerCase());
    });
  }
  // offer type filter selected
  const filteredByType = filteredByModel.map(model => {
    let offers =  model.offers;
    let isModelEvent =  false;
    let hasSpecialEvent = specialEvents.length

    if (hasSpecialEvent){
      isModelEvent = specialEvents.includes(model.modelTag?.label.toLocaleLowerCase());
    }
    offers = hasSpecialEvent ? (isModelEvent && offers.length ? [offers[0]] : []) : offers

    if (dealOfferTypes.length){
      offers = offers.filter((offer: OffersModel) =>
      dealOfferTypes.includes(
        getOfferType(offer.dealOfferTypes[0].toLocaleLowerCase())
      )
    )
    }
    
    return {
      ...model,
      offerCount: offers.length,
      offers
    };
  });


  return dealOfferTypes.length || specialEvents.length ? filteredByType : filteredByModel;
};

/**
 * Clear the filteredOffers to the initial state
 * @param modelOfferMap The list of vehicles filtered by slug
 */
export const clearFilters = (modelOfferMap: TypeMap<OfferInformation>) => ({
  appliedFilters: [],
  filteredOffers: filterOffers(modelOfferMap, [])
});

/**
 * Set the filters to the store
 * @param history Router history
 * @param offersData Special obj data
 */
export const setFilterInformation = (
  history: History,
  offersData: TypeMap<OfferInformation>,
  specialEvent?: SpecialEventConfig
) => {
  // Give format to the filterAttributes to be displayed on the sidebar
  const filterAttributes = buildFilterAttributes(offersData, specialEvent);
  // Set appliedFilters with the URL filters
  const appliedFilters = urlToFilters(history, specialEvent);

  return {
    filterAttributes,
    appliedFilters,
    filteredOffers: filterOffers(offersData, appliedFilters)
  };
};
