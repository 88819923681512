import {Breakpoints, styled} from '@ngw6/us-components';

export const StyledContainer: any = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  min-height: 530px;

  @media (min-width: ${Breakpoints.b960}px) {
    height: calc(100vh - 200px);
  }
`;
