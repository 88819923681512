import { styled } from '@ngw6/us-components';

interface StyledTagContainerProps {
  background: string;
}

export const StyledTagContainer: any = styled.div<StyledTagContainerProps>`
  background: ${({ background }) => background || '#a7a7a7'};
  width: 100%;
  height: 28px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  span {
    margin: 0;
  }
`;

export const StyledHeadOfferWrapper: any = styled.div`
  width: 100%;
  margin-bottom: 35px;
`;

export const StyledImageGroup: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
`;

export const StyledButtonWrapper: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > :first-child {
    margin-right: 16px;
  }
  & > :last-child {
    margin-left: 16px;
  }
`;

export const StyledButtonSection: any = styled.div`
  width: auto;
`;

interface StyledBackgroundProps {
  readonly backgroundColor: string;
}

export const StyledBackground: any = styled.div<StyledBackgroundProps>`
  background: ${props => props.backgroundColor || '#ccab0d'};
  position: absolute;
  padding-top: 46%;
  top: 0px;
  left: 0px;
  right: 0px;

  @media (min-width: 440px) {
    padding-top: 42%;
  }

  @media (min-width: 500px) {
    padding-top: 190px;
  }
`;

interface StyledTextWrapperProps {
  readonly headTextColor: string;
  readonly backgroundSection: string;
  readonly applyBg: boolean;
}

export const StyledTextWrapper: any = styled.div<StyledTextWrapperProps>`
  padding: 10px 10px 0;
  color: ${props => props.headTextColor || '#000'};
  ${(props: StyledTextWrapperProps) =>
    props.applyBg &&
    props.backgroundSection &&
    `background: ${props.backgroundSection}; padding-bottom: 50px;`}
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledImagePlaceholder: any = styled.div``;

export const StyledImageWrapper: any = styled.div`
  position: relative;
  pointer-events: none;

  > img:nth-child(2) {
    transform: scale(1.1);
    margin-top: -32px;
    padding-bottom: 6px;
  }
`;
