import React from 'react';
import {UserMapLocationPin} from '@ngw6/us-components';
interface UserMarkerProps {
  markerSize: string;
  lat: number;
  lng: number;
}
const UserMarker = (props: UserMarkerProps) => (
  <UserMapLocationPin iconSize={props.markerSize}></UserMapLocationPin>
);

export default UserMarker;
