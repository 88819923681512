export const translations = {
  en: {
    defaultLegalDisclaimer:
      'By clicking "Submit," you accept our [Terms of Service](/en/terms.html) and [Privacy Statement](/en/privacy.html) and authorize Volkswagen and its dealer to contact you with marketing information, by calling or texting you at the phone number(s) you’ve provided. You understand these calls or texts may use computer-assisted dialing and/or prerecorded messages. This authorization is not required to complete the purchase or lease of any Volkswagen product.',
    errors: {
      defaultErrorMessage: 'Something Went Wrong',
    },
    offersSection: {
      filter: {
        filterArea: {
          clearAll: 'Clear all',
        },
        filterSidebar: {
          filter: 'Filter',
          clearAll: 'Clear All',
          showAll: 'Show all results',
        },
      },
      filterToolbar: {
        showing: 'Showing',
      },
      infoContent: {
        showing: 'Showing matches at:',
      },
      leftContainerSection: {
        breadcrumbLabel: 'Back to Dealers Page',
        inputZipPlaceholder: 'Enter ZIP Code',
        inputZipLabel: 'Your current location',
        inputZipError: 'Invalid ZIP code',
        noDealersError: 'No dealers in this area',
      },
      rightContainerSection: {
        noDealers: 'Sorry, there are no dealers in this area.',
        anotherZip: 'Please try to enter another ZIP code.',
      },
      modelOfferSection: {
        modelOfferHead: {
          noResults: {
            noOffers:
              'Sorry, there are currently no offers available for this model.',
            contactOrQuote:
              'Contact your dealer or get a quote on this vehicle.',
            getAQuote: 'Get a Quote',
            contactDealer: 'Contact Dealer',
          },
          getAQuote: 'Get a Quote',
          contactDealer: 'Contact Dealer',
        },
        offerCard: {
          offerDetails: 'View offer details',
          getAQuote: 'Get a Quote',
          contactDealer: 'Contact Dealer',
          getCertificate: 'Get Certificate',
          inventoryRedirect: "View Inventory"
        },
      },
    },
    tier1: {
      landing: {
        label1: 'Current',
        label2: 'offers',
      },
    },
    tier2: {
      landing: {
        noDealersError: 'No dealers in this area',
        featuredOffers: {
          offerCard: {
            offerDetails: 'View offer details',
            getAQuote: 'Get a Quote',
            contactDealer: 'Contact Dealer',
            getCertificate: 'Get Certificate',
          },
          offersButtonLabel: 'View all',
          offersButtonTitle: 'Featured Offers',
          noResultsHeader: 'Sorry, there are no offers available at this time.',
          noResultsSubHeader:
            'Contact your local dealer or visit their website to get more information.',
          noResultsBtnLabel1: 'Visit Site',
          noResultsBtnLabel2: 'Contact Dealer',
        },
        findDealer: {
          dealerList: {
            dealerCardWebsite: 'Dealer Website',
          },
          modelDealerCard: {
            dealerDetails: 'Dealer Details \n',
            dealerWebsite: 'Dealer Website',
          },
        },
        serviceOffers: {
          offerCard: {
            viewDetails: 'View Details',
            schedule: 'Schedule',
          },
        },
        inputZipPlaceholder: 'Enter ZIP Code',
        inputZipLabel: 'Your current location',
        inputZipError: 'Invalid ZIP code',
        serviceOffersSectionTitle: 'Service Offers',
        noResultsHeader: 'Sorry, there are no offers available at this time.',
        noResultsSubheader:
          'Check out our other offers or visit your dealer site.',
        noResultsBtnLabel1: 'See Offers',
        noResultsBtnLabel2: 'Visit Dealer Site',
      },
    },
    tier1simplifiedVersion: {
      localOffers: {
        offerCard: {
          offerDetails: 'View offer details',
          getAQuote: 'Get a Quote',
          contactDealer: 'Contact Dealer',
        },
        offersBtnLabel: 'View all',
        offersBtnTitle: 'Your Local Offers',
        noResultsHeader: 'Sorry, there are no offers available at this time.',
        noResultsSubHeader:
          'Contact your local dealer or visit their website to get more information.',
        noResultsBtnLabel1: 'Visit Site',
        noResultsBtnLabel2: 'Contact Dealer',
      },
      inputZipPlaceholder: 'Enter ZIP Code',
      inputZipLabel: 'Your current location',
      inputZipError: 'Invalid ZIP code',
    },
  },
};
