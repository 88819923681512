import {styled} from '@vw-marketing/us-components';

export const StyledModalWrapper: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const StyledSpinnerContainer: any = styled.div`
  display: inline-block;
`;

export const StyledSpinnerWrapper: any = styled.div`
  display: flex;
  background: #fff;
  padding: 50px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.2);

  & > :first-child {
    margin-bottom: 20px;
  }
`;
