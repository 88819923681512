import React from 'react';
import { OffersSection } from '../../offers-section';
import { StyledLandingWrapper } from './styled';
import { useFeatureAppConfig } from '../../../services/use-feature-app-config';
import WSEBanner from '../../../components/wse-banner';
import {
  StyledImageContainer,
  StyledMastheadContainer,
} from '../../masthead/styled';

export const Landing: React.FC = (): JSX.Element => {
  const { wseBannerConfig } = useFeatureAppConfig();
  const wseBanner = wseBannerConfig?.landingPage;

  return (
    <StyledLandingWrapper>
      {wseBanner?.enabled ? (
        <StyledMastheadContainer>
          <StyledImageContainer>
            <WSEBanner wseBanner={wseBanner} />
          </StyledImageContainer>
        </StyledMastheadContainer>
      ) : (
        <></>
      )}
      <OffersSection />
    </StyledLandingWrapper>
  );
};

export default Landing;
