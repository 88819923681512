import {AppConstants} from '../../../utils/app-constants';
import {
  DealerModel,
  TypeMap,
  OfferInformation
} from '../../typings/incentive-store';

/**
 * Get selected offer by offerId
 * @param dealer
 * @param offersData
 * @param offerId
 */
export const getModalByOfferId = (
  dealer: DealerModel,
  offersData: TypeMap<OfferInformation>,
  offerId: number
) => {
  let offer;
  const offersKeys = Object.keys(offersData);
  for (var i = 0; i < offersKeys.length; i++) {
    if (offersData[offersKeys[i]].offers.length > 0) {
      const tempOffer = offersData[offersKeys[i]].offers.find(
        (offer: any) => offer.dealId == offerId
      );
      if (tempOffer) {
        offer = {
          ...tempOffer,
          imageData: offersData[offersKeys[i]].imageData,
          mediaImageUrl: offersData[offersKeys[i]].imageData.modelImage,
          slug: offersData[offersKeys[i]].slug,
          modelKey: offersData[offersKeys[i]].modelKey,
          modelDisplayName: offersData[offersKeys[i]].modelDisplayName
        };
        break;
      }
    }
  }
  if (offer) {
    return {
      active: true,
      offer,
      dealer,
      formType: AppConstants.OfferDetailsFormType,
      formName: AppConstants.OfferDetailsFormName
    };
  }
  return undefined;
};
