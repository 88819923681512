import {
  OffersModel,
  IncentiveStoreModel,
  OfferInformation,
  DealerModel,
  AttributeModel,
  TypeMap
} from '../hooks-store/typings/incentive-store';
import {
  FeatureAppTrackingManager,
  getDefaultTrackingEvent
} from '../context/use-tracking-manager';
import {AppConstants} from './app-constants';
import {StoreModel} from '../hooks-store/typings/store';
import _, { isEmpty } from 'lodash';

import {localModelOrder} from '../config/tier-1/model-order';

interface FormErrorFields {
  formFields?: string[];
  errorFields: string[];
  errorField?: string;
  errorMessage?: string;
}

export interface FormField {
  formfieldname: string;
  formfieldid?: string;
}

export interface ErrorFormField {
  FieldNameandID: FormField[];
}

const fieldsErrorCodes = {
  'Invalid email': 'FE01',
  'Field required': 'FE02',
  'Invalid phone number': 'FE03',
  'Invalid last name': 'FE04',
  'Last name must be at least 1 characters': 'FE05',
  'Invalid first name': 'FE06',
  'First name must be at least 1 characters': 'FE07',
  'Message must be at least 1 characters': 'FE08',
  'Message must be at most 1000 characters': 'FE09',
  'Please select valid model': 'FE10',
  'Please select valid trim': 'FE11',
  '': 'FE00'
};

const formTypeMap = {
  [AppConstants.ContactDealerFormType]: 'contact',
  [AppConstants.GetAQuoteFormType]: 'quote',
  [AppConstants.OfferDetailsFormType]: 'quote',
  [AppConstants.ScheduleServiceFormType]: 'servicerequest',
  [AppConstants.ScheduleServiceDetailFormType]: 'servicerequest',
  ZIPCODE: 'otherform'
};

const COUNTRY_CODE = "US"

/**
 * Handle form button link tag
 * @param offer
 * @param name
 * @param trackingManager
 * @param store
 * @param linkUrl
 */
export const onHandleFormButtonTracking = (
  offer?: OffersModel,
  name?: string,
  trackingManager?: FeatureAppTrackingManager,
  store?: IncentiveStoreModel,
  linkUrl?: string,
  dealer?: DealerModel
) => {
  // Tracking section
  let customEvent = getDefaultTrackingEvent();
  customEvent.configuration = getConfigurationData(
    offer,
    customEvent.configuration
  );
  customEvent.partner = getDealerData(
    dealer || store?.dealer,
    customEvent.partner
  );
  customEvent.link = {
    ...customEvent.link,
    url: linkUrl || `${name} modal form`,
    name: name
  };
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    contentId: linkUrl || `${name} modal form`
  };
  // customEvent.form = {
  //   ...customEvent.form,
  //   FormStart: true
  // };

  if (
    // !store?.tier2 &&
    store?.pageName !== AppConstants.Tier1SimplifiedVersion
  ) {
    customEvent.eventInfo = {
      ...customEvent.eventInfo,
      sectionName: offer?.modelDisplayName
    };
  }
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_VehiclePromotionsButton_Click',
      store,
      customEvent
    );
};

/**
 * Vehicle button form tag
 * @param offer
 * @param name
 * @param trackingManager
 * @param store
 */
export const onHandleBasicFormButtonLink = (
  offer: OfferInformation,
  name: string,
  trackingManager: FeatureAppTrackingManager,
  store?: IncentiveStoreModel,
  url?: string
) => {
  // Tracking section
  let customEvent = getDefaultTrackingEvent();
  let carlineIdObj = localModelOrder.find(
    el => el.modelKey === offer?.modelKey
  );
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: offer.modelDisplayName,
    CarlineId: carlineIdObj?.carlineId
  };
  customEvent.link = {
    ...customEvent.link,
    url: url || `open ${name} form modal`,
    name: name
  };
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    contentId: url || `open ${name} modal form`
  };

  if (
    // !store?.tier2 &&
    store?.pageName !== AppConstants.Tier1SimplifiedVersion
  ) {
    customEvent.eventInfo = {
      ...customEvent.eventInfo,
      sectionName: offer?.modelDisplayName
    };
  }
  customEvent.partner = getDealerData(store?.dealer, customEvent.partner);

  trackingManager.trackInventoryGlobalEvent(
    'VWBasic_VehicleButton_Click',
    store,
    customEvent
  );
};

/**
 * Throw trigger when carousel is loaded
 * @param offer
 * @param trackingManager
 */
export const onHandleCarouselLoaded = (
  offer?: OfferInformation | any,
  trackingManager?: FeatureAppTrackingManager,
  isTier1?: boolean,
  sectionName?: string,
  dealer?: DealerModel
) => {
  // Tracking section on component mount
  let customEvent = getDefaultTrackingEvent();
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: offer?.modelDisplayName
  };
  if (isTier1 || sectionName) {
    customEvent.eventInfo = {
      ...customEvent.eventInfo,
      sectionName: sectionName || offer?.modelDisplayName,
      contentId: sectionName || offer?.modelDisplayName
    };
  }
  customEvent.link = {
    ...customEvent.link,
    url: `Loaded carousel ${offer && `for ${offer?.modelKey}`}`,
    name: `loaded carousel`
  };
  customEvent.partner = getDealerData(dealer, customEvent.partner);
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_SliderElement_Load',
      undefined,
      customEvent
    );
};

/**
 * Tracking function for slider actions
 * @param type
 * @param direction
 */
export const onSlideActionTracking = (
  type: 'click' | 'swipe',
  direction: string,
  offer?: OfferInformation,
  trackingManager?: FeatureAppTrackingManager,
  store?: IncentiveStoreModel,
  dealer?: DealerModel,
  isTier1?: boolean,
  sectionName?: string
) => {
  const event =
    type === 'click' ? 'VWBasic_Link_Click' : 'VWBasic_Element_Swipe';
  let customEvent = getDefaultTrackingEvent();
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: offer?.modelDisplayName
  };

  if (isTier1 || sectionName) {
    customEvent.eventInfo = {
      ...customEvent.eventInfo,
      sectionName: sectionName || offer?.modelDisplayName
    };
  }
  customEvent.link = {
    ...customEvent.link,
    url: `Move carousel to ${direction}`,
    name: direction
  };
  customEvent.partner = getDealerData(
    dealer || store?.dealer,
    customEvent.partner
  );

  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(event, store, customEvent);
};

/**
 * Handler dealer change tracking
 * @param dealer
 * @param name
 * @param trackingManager
 * @param store
 */
export const onHandleDealerTrackingEvent = (
  dealer?: DealerModel,
  name?: string,
  trackingManager?: FeatureAppTrackingManager,
  store?: IncentiveStoreModel,
  type?: 'map icon' | 'button click'
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.partner = getDealerData(dealer, customEvent.partner);
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    sectionName: 'Find a Dealer',
    contentId: type
  };
  customEvent.link = {
    ...customEvent.link,
    url: type === 'map icon' ? name : dealer?.url,
    name: name
  };
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_DealerButton_Click',
      store,
      customEvent
    );
};

/**
 * handle no form vehicle link
 * @param linkRef
 * @param name
 * @param trackingManager
 * @param store
 */
export const onHandlerBasicVehicleLink = (
  linkRef?: string,
  name?: string,
  modelName?: string,
  trackingManager?: FeatureAppTrackingManager,
  store?: IncentiveStoreModel,
  dealer?: DealerModel
) => {
  console.log('modelname', modelName?.toLocaleLowerCase().trim());
  let carlineIdObj = localModelOrder.find(
    el =>
      el.modelName.toLocaleLowerCase().trim() ===
      modelName?.toLocaleLowerCase().trim()
  );
  let customEvent = getDefaultTrackingEvent();
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: modelName,
    CarlineId: carlineIdObj?.carlineId
  };
  customEvent.link = {
    ...customEvent.link,
    url: linkRef,
    name: name
  };
  customEvent.partner = getDealerData(dealer, customEvent.partner);
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_VehicleLink_Click',
      store,
      customEvent
    );
};

/**
 * Filter accordion tracking handle
 * @param status
 * @param filterType
 * @param trackingManager
 * @param store
 */
export const onHandleBasicAccordionAction = (
  status: boolean,
  filterType: string,
  trackingManager: FeatureAppTrackingManager,
  store: IncentiveStoreModel
) => {
  //tracking section
  let customEvent = getDefaultTrackingEvent();

  customEvent.link = {
    ...customEvent.link,
    url: status
      ? `Open Accordion ${filterType}`
      : `Close Accordion ${filterType}`,
    name: filterType
  };
  customEvent.filter = {
    ...customEvent.filter,
    name: filterType
  };

  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    contentId: filterType,
    sectionName: 'Filters'
  };

  trackingManager.trackInventoryGlobalEvent(
    status ? 'VWBasic_AccordionOpen_Click' : 'VWBasic_AccordionClose_Click',
    store,
    customEvent
  );
};

/**
 * handle filter click action tracking
 * @param filterType
 * @param attributeText
 * @param trackingManager
 * @param store
 */
export const onHandleFilterClickAction = (
  filterType: string,
  attributeText: string,
  trackingManager: FeatureAppTrackingManager,
  store: IncentiveStoreModel
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.eventInfo = {...customEvent.eventInfo, sectionName: 'Filters'};
  customEvent.filter = {
    ...customEvent.filter,
    type: 'single_value',
    name:
      filterType.toUpperCase() === 'OFFERS'
        ? 'Offer category'
        : 'Carline Group',
    values: [attributeText]
  };
  customEvent.partner = getDealerData(store.dealer, customEvent.partner);
  trackingManager.trackInventoryGlobalEvent(
    'VWBasic_FilterSelect_Click',
    store,
    customEvent
  );
};
/**
 * Handle form status tracking
 * @param type
 * @param trackingManager
 * @param offer
 * @param formValues
 * @param formId
 * @param error
 */
export const onHandleZipCodeErrorEvent = (
  trackingManager?: FeatureAppTrackingManager,
  error?: FormErrorFields,
  errorMessage?: {message?: string; code?: string}
) => {
  let customEvent = getDefaultTrackingEvent();

  customEvent.form = getBasicFormData(
    'ZIPCODE',
    'Zipcode Request',
    customEvent.form,
    false
  );

  if (error) {
    customEvent.form = getErrorFields(error, customEvent.form);
  }
  if (errorMessage) {
    customEvent.error = {
      ...customEvent.error,
      code:
        errorMessage.code ||
        fieldsErrorCodes[errorMessage.message || ''] ||
        fieldsErrorCodes[''],
      message: errorMessage.message
    };
  }
  // customEvent.filter = {
  //   ...customEvent.filter,
  //   filterlist: [
  //     {
  //       type: 'single_value',
  //       name: 'ZIP',
  //       values: [store?.zip]
  //     }
  //   ]
  // };
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_VehiclePromotionsFormErrorMessage_Load',
      undefined,
      customEvent
    );
};

/**
 * Handle form status tracking
 * @param type
 * @param trackingManager
 * @param offer
 * @param formValues
 * @param formId
 * @param error
 */
export const onHandleFormSubmitTracking = (
  type: 'load' | 'success' | 'error',
  requestType?: string,
  formName?: string,
  trackingManager?: FeatureAppTrackingManager,
  offer?: OffersModel,
  dealer?: DealerModel,
  formValues?: any,
  formId?: string,
  error?: FormErrorFields,
  errorMessage?: {message?: string; code?: string},
  testDriveComment?:any

) => {
  let customEvent = getDefaultTrackingEvent();
  const trackingType = {
    load: 'VWBasic_VehiclePromotionsFormCTA_Click',
    success: 'VWBasic_VehiclePromotionsFormSuccessMessage_Load',
    error: 'VWBasic_VehiclePromotionsFormErrorMessage_Load'
  };
  const event = trackingType[type];
  customEvent.configuration = getConfigurationData(
    offer,
    customEvent.configuration
  );

  if (type === 'load')
    customEvent.link = {
      ...customEvent.link,
      url: 'Submit form',
      name: `Submit`
    };

  if (type !== 'error') {
    customEvent.form = {
      ...customEvent.form,
      PrefContactChannels: formValues?.preferredContactMethod,
      LeadID: createSuccessLeadId(formId,COUNTRY_CODE)
    };
  }

  customEvent.form = getBasicFormData(
    requestType,
    formName,
    customEvent.form,
    false,
    testDriveComment
  );

  if (error) {
    customEvent.form = getErrorFields(error, customEvent.form);
  }
  if (errorMessage) {
    customEvent.error = {
      ...customEvent.error,
      code: errorMessage.code || fieldsErrorCodes[errorMessage.message || ''],
      message: errorMessage.message
    };
  }
  customEvent.partner = getDealerData(dealer, customEvent.partner);

  // if (type === 'success') {
  //   customEvent.form = getQuestionarieData(formValues, formTypeMap[requestType || ""] ,customEvent.form);
  // }

  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(event, undefined, customEvent);
};

/**
 * Handle form load
 * @param type
 * @param formType
 * @param formName
 * @param offer
 * @param trackingManager
 */
export const onHandleLoadTracking = (
  type: 'load' | 'close',
  formType?: string,
  formName?: string,
  offer?: OffersModel,
  dealer?: DealerModel,
  trackingManager?: FeatureAppTrackingManager
) => {
  const event =
    type === 'load'
      ? 'VWBasic_VehiclePromotionsFormLayer_Load'
      : 'VWBasic_LayerClose_Click';
  let customEvent = getDefaultTrackingEvent();
  if (type !== 'close') {
    customEvent.configuration = getConfigurationData(
      offer,
      customEvent.configuration
    );
    customEvent.form = getBasicFormData(
      formType,
      formName,
      customEvent.form,
      type === 'load'
    );
  }
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    contentId:
      formType === AppConstants.ContactDealerFormType
        ? 'Contact Dealer modal form'
        : formType === AppConstants.GetAQuoteFormType
        ? 'Get a Quote modal form'
        : 'Schedule service modal form'
  };
  customEvent.link = {
    ...customEvent.link,
    url: type === 'close' ? 'close modal form' : 'open modal form',
    name: type === 'close' ? 'close form' : 'open form'
  };
  customEvent.partner = getDealerData(dealer, customEvent.partner);
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(event, undefined, customEvent);
};

/**
 * handle zipcode change tracking
 * @param name
 * @param trackingManager
 * @param store
 */
export const onHandleInputTrackingEvent = (
  name: string,
  zipCode: string,
  trackingManager?: FeatureAppTrackingManager,
  store?: IncentiveStoreModel
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.link = {
    ...customEvent.link,
    url: `update result with ${zipCode}`,
    name: name
  };
  customEvent.filter = {
    ...customEvent.filter,
    filterlist: [
      {
        type: 'single_value',
        name: 'ZIP',
        values: [zipCode]
      }
    ]
  };

  // customEvent.eventInfo = {
  //   ...customEvent.eventInfo,
  //   contentId: 'single_value'
  // };

  // customEvent.link = {
  //   ...customEvent.link,
  //   name: `Click on ${zipCode} in the filter menu`
  // };

  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_FilterSearchButton_Click',
      store,
      customEvent
    );
};

/**
 * handle zipcode change tracking
 * @param name
 * @param trackingManager
 * @param store
 */
export const onVWBasicLayerloadEvent = (
  trackingManager?: FeatureAppTrackingManager,
  store?: IncentiveStoreModel
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.eventInfo = {...customEvent.eventInfo, contentId: 'ZIP Layer'};
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_Layer_Load',
      store,
      customEvent
    );
};

export const onHandlerFilterSeachLoad = (
  trackingManager: FeatureAppTrackingManager,
  store: StoreModel,
  modelKey?: string
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.filter = getAppliedFilterList(store, customEvent.filter);
  customEvent.filter = modelKey
    ? getResultsFilterDataByModel(modelKey, store, customEvent.filter)
    : getResultsFilterData(store, customEvent.filter);
  customEvent.partner = getDealerData(store.dealer, customEvent.partner);
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_FilterSearchResult_Load',
      store,
      customEvent
    );
};

export const onHandleBasicaServiceBookingClickEvent = (
  name: string,
  trackingManager: FeatureAppTrackingManager,
  store: StoreModel,
  offer: OffersModel
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    sectionName: 'Service Offers'
  };
  customEvent.link = {
    ...customEvent.link,
    name: name,
    url: 'open service form modal'
  };
  customEvent.product = getProductForServiceEvent(offer, customEvent.product);
  customEvent.partner = getDealerData(store.dealer, customEvent.partner);
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_ServiceBookingButton_Click',
      store,
      customEvent
    );
};

export const onHandlerServiceLayerLoadEvent = (
  formType?: string,
  formName?: string,
  dealer?: DealerModel,
  trackingManager?: FeatureAppTrackingManager,
  offer?: OffersModel
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.form = getBasicFormData(
    formType,
    formName,
    customEvent.form,
    true
  );
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    sectionName: 'Service Offers'
  };
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    contentId: 'Service modal form'
  };
  customEvent.product = getProductForServiceEvent(offer, customEvent.product);
  customEvent.partner = getDealerData(dealer, customEvent.partner);
  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_ServiceBookingFormLayer_Load',
      undefined,
      customEvent
    );
};

export const onHandlerServiceFormLayerEvents = (
  type: 'load' | 'success',
  requestType?: string,
  formName?: string,
  trackingManager?: FeatureAppTrackingManager,
  dealer?: DealerModel,
  formValues?: any,
  formId?: string,
  offer?: OffersModel
) => {
  let customEvent = getDefaultTrackingEvent();
  const trackingType = {
    load: 'VWBasic_ServiceBookingFormCTA_Click',
    success: 'VWBasic_ServiceBookingFormSubmissionSuccessMessage_Load'
  };
  const event = trackingType[type];

  if (type === 'load')
    customEvent.link = {
      ...customEvent.link,
      url: 'Submit form',
      name: `Submit`
    };

  customEvent.form = {
    ...customEvent.form,
    PrefContactChannels: formValues?.preferredContactMethod,
    LeadID: createSuccessLeadId(formId, COUNTRY_CODE)
  };
  customEvent.ownership = getOwnershipForServiceEvent(
    formValues['modelName'],
    formValues['trim'],
    customEvent.ownership
  );

  customEvent.product = getProductForServiceEvent(offer, customEvent.product);
  customEvent.form = getBasicFormData(
    requestType,
    formName,
    customEvent.form,
    false
  );
  customEvent.partner = getDealerData(dealer, customEvent.partner);

  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(event, undefined, customEvent);
};

/**
 * Tracking function for slider actions
 * @param type
 * @param direction
 */
export const onBasicLinkTrackingEvent = (
  offer?: OffersModel,
  name?: string,
  url?: string,
  trackingManager?: FeatureAppTrackingManager,
  store?: IncentiveStoreModel,
  dealer?: DealerModel,
  formType?: string
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.configuration = {
    ...customEvent.configuration,
    CarlineName: offer?.modelDisplayName
  };

  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    contentId:
      formType === AppConstants.ContactDealerFormType
        ? 'Contact Dealer modal form'
        : formType === AppConstants.GetAQuoteFormType
        ? 'Get a Quote modal form'
        : formType === AppConstants.OfferDetailsFormType
        ? 'Get a Quote modal form'
        : 'Schedule service modal form',
    sectionName:
      formType === AppConstants.ScheduleServiceDetailFormType ||
      formType === AppConstants.ScheduleServiceFormType
        ? 'Service Offers'
        : undefined
  };
  customEvent.link = {
    ...customEvent.link,
    url: url,
    name: name
  };
  customEvent.partner = getDealerData(
    dealer || store?.dealer,
    customEvent.partner
  );

  trackingManager &&
    trackingManager.trackInventoryGlobalEvent(
      'VWBasic_Link_Click',
      store,
      customEvent
    );
};
// Auxiliar functions

function getDealerData(dealer?: DealerModel, eventObject?: any) {
  return {
    ...eventObject,
    informationBnr: dealer?.dealerid,
    informationName: dealer?.name,
    informationDistance: dealer?.distance,
    informationZIP: dealer?.postalcode
  };
}

function getBasicFormData(
  formType?: string,
  formName?: string,
  eventObject?: any,
  isFormStart?: boolean,
  testDriveComment?:any
) {

  let questionarie = <any>{
    QuestionAnswerSet: {
      content: [] as any,
    },
  };

  if(testDriveComment && !isEmpty(testDriveComment.current)){
    let question = {
      question: "test-drive",
      answer: [
        {
          type: 'string',
          value: testDriveComment.current,
        },
      ],
    };  
    questionarie.QuestionAnswerSet.content.push(question);
  }

  return {
    ...eventObject,
    FormType: formType ? formTypeMap[formType] : undefined,
    FormName: formName,
    FormStart: isFormStart ? true : undefined,
    FormVersion: '1',
    Questionnaire: questionarie,
  };
}

function getConfigurationData(offer?: OffersModel, event?: any) {
  let carlineIdObj = localModelOrder.find(
    el => el.modelKey === offer?.modelKey
  );

  return {
    ...event,
    CarlineName:
      (offer?.dealCarModelName?.length && offer?.dealCarModelName[0]) ||
      carlineIdObj?.modelName ||
      undefined,
    CarlineId: carlineIdObj?.carlineId,
    SalesgroupName:
      (offer?.dealCarTrimName?.length && offer?.dealCarTrimName[0]) ||
      undefined,
    PriceCampaignID: [offer?.dealId ? `${offer?.dealId}` : undefined],
    PriceCampaignDescription: [offer?.dealTitle],
    PriceCampaignValue: offer?.dealPricePerMonth
      ? [offer?.dealPricePerMonth]
      : undefined
  };
}

function getErrorFields(error: FormErrorFields, event: any) {
  const errorFields: any[] = [];
  const formFields: any[] = [];
  error?.errorFields?.map((key: string) => {
    errorFields.push({'form field name': key});
  });
  error?.formFields?.map((key: string) => {
    formFields.push({'form field name': key});
  });
  return {
    ...event,
    ErrorFormFields: {FieldNameandID: errorFields},
    FormFields: formFields,
    FormFieldName: error.errorField
  };
}

function getAppliedFilterList(store: IncentiveStoreModel, event: any) {
  const filterList: any[] = [
    {
      type: 'single_value',
      name: 'ZIP',
      values: [store.zip]
    }
  ];

  const groupedFilters = _.groupBy(store.appliedFilters, 'filterKey');
  const filtersKeys = Object.keys(groupedFilters);
  filtersKeys?.map((key: string) => {
    const values: string[] = [];
    groupedFilters[key].map((valueAttribute: AttributeModel) =>
      values.push(valueAttribute.text)
    );
    filterList.push({
      type: 'multiple_value',
      name: key === 'offers' ? 'OC' : 'CG',
      values: values
    });
  });

  return {
    ...event,
    filterlist: filterList
  };
}
/**
 * Get offers
 */
const getLocalOffersByModelKey = (
  modelKey: string,
  offersData: TypeMap<OfferInformation>
) => {
  const model: any = offersData.hasOwnProperty(modelKey)
    ? offersData[modelKey]
    : [];

  return model.offers && model.offers.length > 0
    ? model.offers.map((offer: OffersModel) => ({
        ...offer,
        modelKey: model.modelKey,
        modelDisplayName: model.modelDisplayName
      }))
    : [];
};

function getResultsFilterDataByModel(
  modelKey: string,
  store: IncentiveStoreModel,
  event: any
) {
  const filterResults: any[] = [];
  let offersCount: number = 0;

  const filteredOffers = getLocalOffersByModelKey(modelKey, store.offersData);
  filteredOffers?.map((offer: OffersModel) => {
    filterResults.push({
      campaignId: `${offer.dealId}`
    });
    offersCount += 1;
  });

  return {
    ...event,
    results: filterResults.length ? filterResults : undefined,
    numberOfResults: offersCount
  };
}

function getResultsFilterData(store: IncentiveStoreModel, event: any) {
  const filterResults: any[] = [];
  let offersCount: number = 0;
  store?.filteredOffers?.map((offer: OfferInformation) => {
    offer.offers.map((offer: OffersModel) => {
      filterResults.push({
        campaignId: `${offer.dealId}`
      });
    });
    offersCount += offer.offers.length;
  });

  return {
    ...event,
    results: filterResults,
    numberOfResults: offersCount
  };
}

function getProductForServiceEvent(offer?: OffersModel, event?: any) {
  return {
    ...event,
    List: [
      {
        Manufacturer: 'Volkswagen',
        ID: `${offer?.dealId}`,
        Name: offer?.dealTitle
        // Type: undefined,
        // Category: undefined, //['Wallbox']
        // StockLevel: undefined,
        // Price: undefined, //{Currency: 'EUR',value: 320.0}
        // Quantity: undefined, // 1,
        // Attributes: undefined //[{name: 'Charging Power',value: '11 kW'}]
      }
    ]
  };
}

function getOwnershipForServiceEvent(
  modelName?: string,
  trimName?: string,
  event?: any
) {
  return {
    ...event,
    vehicle: [
      {
        carlineName: modelName,
        model: trimName
      }
    ]
  };
}

/**
 * Create Success request response according with configured data
 * @param formId request form response
 * @param successRequestCountryCode : Configured Tracking Variable
 * @param successRequestLeadId : Configured Tracking Variable
 * @returns new request Id | current request response | undefined
 */
 function createSuccessLeadId(formId?: string | undefined, successRequestCountryCode?: string | undefined, successRequestLeadId?: string | undefined): string | undefined {
  if (!formId) return undefined;
  let newRequestId = formId;
  if (successRequestLeadId) {
    newRequestId= `${successRequestLeadId}-${newRequestId}`;
  }
  if (successRequestCountryCode) {
    newRequestId= `${successRequestCountryCode}-${newRequestId}`;
  }
  return newRequestId;
}

// function getQuestionarieData (formValues: object, formType:string, event: any) {
//    let questionarie = <any>{
//      QuestionAnswerSet: {
//        id: formType,
//        content: [] as any
//      }
//    };
//    for (const [key, value] of Object.entries(formValues)) {
//      let question = {
//        question: key,
//        answer: [
//          {
//            type: 'string',
//            value: value
//          }
//        ]
//      };
//      questionarie.QuestionAnswerSet.content.push(question);
//    }
//    return {...event, Questionnaire: questionarie}
// }


