import {styled} from '@ngw6/us-components';

export const StyledFilterSectionWrapper: any = styled.div`
  width: 100%;
`;

interface StyledFilterNameProps {
  readonly isOpen: boolean;
}

export const StyledFilterName: any = styled.button<StyledFilterNameProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-bottom: ${(props: StyledFilterNameProps) =>
    props.isOpen ? '0' : '24px'};
  margin: 24px 0;
  border: none;
  border-bottom: ${(props: StyledFilterNameProps) =>
    props.isOpen ? 'none' : 'solid 1px #ddd'};
  cursor: pointer;
  background-color: #fff;

  &:focus {
    outline: none;
  }
`;

export const StyledAttributesWrapper: any = styled.div`
  border-bottom: solid 1px #dfe4e8;
  padding-bottom: 10px;
`;
