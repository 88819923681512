import { fetchGraphQL } from './fetch-graphql';
import { createQueryString } from './helpers/create-query';
import { handleServerResponse } from './helpers/handle-server-response';
import {  FetchOffersByLmaId, ServiceConfig } from './typing';
import { validateGraphQLServer } from './helpers/validations';
import { fetchOffersNational } from './fetch-offers-national';
import { PRODUCT_OFFER_BY_LMA_ID } from './queries/offers-by-lma';

export const fetchOffersByLmaId = async ({
  lmaId,
  offerType,
  graphQLServer,
}: FetchOffersByLmaId) => {
  if (!lmaId) return fetchOffersNational({graphQLServer})
  if (!offerType) offerType = "special";
  const requestBody = createQueryString(PRODUCT_OFFER_BY_LMA_ID, { lmaId, offerType });
  try {
    validateGraphQLServer(graphQLServer);
    const response = await fetchGraphQL(graphQLServer as ServiceConfig, requestBody);
    if (response.data && response.data.offers) {
      return handleServerResponse(response.data.offers, false);
    }
    return handleServerResponse(response, true);
  } catch (error) {
    return handleServerResponse(error, true);
  }
};
