import {Breakpoints, styled} from '@ngw6/us-components';

export const StyledOffersSectionWrapper: any = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 10px 20px;
  @media (min-width: ${Breakpoints.b960}px) {
    padding: 0;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    flex-wrap: nowrap;
  }
`;

export const StyledLeftContainer: any = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.b1280}px) {
    width: 40%;
  }
`;

export const StyledRightContainer: any = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.b1280}px) {
    width: 60%;
  }
`;

export const OverflowWrapper: any = styled.div`
  overflow-x: hidden;
  width: 100%;
`;

interface LayoutWrapperProps {
  readonly isTier2: boolean;
}

export const LayoutWrapper: any = styled.div<LayoutWrapperProps>`
  display: flex;
  width: 100%;
  padding: 0;
  overflow-x: visible;
  padding-top: ${(props: LayoutWrapperProps) =>
    props.isTier2 ? '15px;' : '30px;'};

  @media (min-width: ${Breakpoints.b960}px) {
    padding: 0 ${(props: any) => props.theme.size.grid002};
    padding-top: 30px;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    padding-top: ${(props: LayoutWrapperProps) =>
      props.isTier2 ? '50px;' : '30px;'};
  }
`;
