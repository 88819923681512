import {
  BreakpointWrapper,
  Breakpoints,
  CCustomerCenter,
  CInternet,
  Container,
  ContainerGutter,
  ContainerWrap,
  DisableFocusWrapper,
  Link,
  PinPlace,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight
} from '@ngw6/us-components';
import React from 'react';
import {DealerModel} from '../../../../../../hooks-store/typings/incentive-store';
import {stringToPhoneFormat} from '../../../../../../utils/general';
import DealerInformationDetail from '../../../../../dealer-information-detail';
import {
  StyledDealerInformationWrapper,
  StyledDealerNameContainer,
  StyledDealerWrapper,
  StyledLinkWrapper
} from './styled';

interface LocalDealerInformationProps {
  readonly dealer: DealerModel;
  onHandleDealerUrlTracking: (name: string) => void;
}

export const LocalDealerInformation: React.FC<LocalDealerInformationProps> = (
  props
): JSX.Element | null => {
  const {dealer, onHandleDealerUrlTracking} = props;

  return (
    <>
      <StyledDealerNameContainer>
        <TextCustom
          appearance={TextAppearance.headline200}
          tag={TextTag.h2}
          style={{
            [Breakpoints.default]: {
              fontWeight: TextWeight.bold,
              fontSize: TextSize.TextSize32,
              lineHeight: TextLineHeight.TextLineHeight36
            }
          }}
        >
          Your Local Dealer
        </TextCustom>
        <TextCustom
          appearance={TextAppearance.headline200}
          tag={TextTag.h2}
          style={{
            [Breakpoints.default]: {
              fontSize: TextSize.TextSize32,
              lineHeight: TextLineHeight.TextLineHeight36,
              fontWeight: TextWeight.light
            }
          }}
        >
          {dealer.name}
        </TextCustom>
      </StyledDealerNameContainer>
      <StyledDealerWrapper>
        <StyledDealerInformationWrapper>
          <Container
            gutter={ContainerGutter.static300}
            wrap={ContainerWrap.always}
          >
            <DealerInformationDetail
              Icon={PinPlace}
              label1={dealer?.address1}
              label2={`${dealer?.city}, ${dealer?.state} ${dealer?.postalcode}`}
            />
            <BreakpointWrapper max={Breakpoints.b560}>
              <DisableFocusWrapper>
                <Link href={dealer ? `tel: ${dealer.phone}` : ''}>
                  <DealerInformationDetail
                    Icon={CCustomerCenter}
                    label1={stringToPhoneFormat(dealer ? dealer.phone : '')}
                  />
                </Link>
              </DisableFocusWrapper>
            </BreakpointWrapper>
            <BreakpointWrapper min={Breakpoints.b560}>
              <DealerInformationDetail
                Icon={CCustomerCenter}
                label1={stringToPhoneFormat(dealer ? dealer.phone : '')}
              />
            </BreakpointWrapper>
            {dealer.url && dealer.url !== '' && (
              <DisableFocusWrapper>
                <DealerInformationDetail
                  Icon={CInternet}
                  textCustom={
                    <StyledLinkWrapper>
                      <TextCustom
                        appearance={TextAppearance.copy100}
                        style={{
                          [Breakpoints.default]: {
                            fontWeight: TextWeight.bold,
                            fontSize: TextSize.TextSize14,
                            lineHeight: TextLineHeight.TextLineHeight22
                          }
                        }}
                      >
                        <Link
                          href={dealer.url}
                          target="_blank"
                          isInline
                          onClick={onHandleDealerUrlTracking.bind(
                            null,
                            'Visit Dealer Site'
                          )}
                        >
                          Visit Dealer Site
                        </Link>
                      </TextCustom>
                    </StyledLinkWrapper>
                  }
                />
              </DisableFocusWrapper>
            )}
          </Container>
        </StyledDealerInformationWrapper>
      </StyledDealerWrapper>
    </>
  );
};
