import {styled} from '@ngw6/us-components';

export const StyledListItem: any = styled.button`
  display: flex;
  align-items: center;
  margin: 0 10px 10px 0;
  background-color: #ccc;
  border-radius: 5px;
  padding: 5px 20px;
  background-color: #c2cacf;
  border: none;
  cursor: pointer;
  color: #3c484d;

  &:focus {
    outline: none;
  }
`;

export const StyledIcon: any = styled.div`
  margin-left: 7px;
  margin-bottom: 2px;

  svg {
    fill: #3c484d;
  }
`;
