// All the initial data needed to start to work with the this store

// Incentive store types
import isBrowser from 'is-browser';
import {
  FilterAttributeModel,
  IncentiveStoreModel
} from '../../typings/incentive-store';

/**
 * List of filters shown in the filter sidebar
 */
export const initialFilterAttributes: FilterAttributeModel[] = [
  {
    name: 'Offers',
    filterKey: 'offers',
    attributes: []
  },
  {
    name: 'Model',
    filterKey: 'model',
    attributes: []
  }
];

/**
 * The initial data state for this store
 */
export function getInitialState(): IncentiveStoreModel {
  return {
    pageName: '',
    status: '',
    isStoreCloned: false,
    ssr: !isBrowser,
    offersData: {},
    dealers: [],
    dealer: undefined,
    filterAttributes: [],
    appliedFilters: [],
    filteredOffers: [],
    mobileModalFilterActivated: false,
    zip: '',
    ipZip: '',
    zipNoDealers: false,
    pendingProcessState: false,
    modalZipState: {
      activeZipModal: false,
      zipError: false,
      zipTemp: ''
    },
    offerId: undefined,
    modalFormData: {
      active: false,
      offer: undefined,
      dealer: undefined,
      formType: '',
      formName: ''
    },
    tier2: false,
    // ==================== Tier 2 ================== //
    serviceOffersData: [],
    authoredLmaDetail: {
      name: '',
      vanityName: '',
      vanityUrlName: '',
      mastheadImage: ''
    },
    location: {
      zip: '',
      lat: 0,
      lng: 0,
      error: true
    },
    errorHandling: [],
    modelsConfig: undefined
  };
}
