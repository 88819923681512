import {
  Close,
  Text,
  TextAppearance,
  TextColor,
  TextTag
} from '@vw-marketing/us-components';
import React from 'react';
import {scrollToTop} from '../../../../../utils/general';
import {StyledIcon, StyledListItem} from './styled';
import _ from 'lodash';

export const ListItem: React.FC<any> = (props: any): JSX.Element => {
  const {filter, updateAppliedFilter, setPendingProcessState, history} = props;
  /**
   * Update the applied filters using the check event
   */
  const updateFilter = () => {
    updateAppliedFilter(filter, history, true);
    setTimeout(() => {
      setPendingProcessState(false);
      scrollToTop(500);
    }, 1000);
  };

  return (
    <StyledListItem onClick={updateFilter}>
      <Text
        appearance={TextAppearance.label100}
        tag={TextTag.span}
        color={TextColor.inherit}
      >
        {filter.text}
      </Text>
      <StyledIcon>
        <Close variant="small" />
      </StyledIcon>
    </StyledListItem>
  );
};
