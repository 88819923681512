import {
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight
} from '@ngw6/us-components';
import React from 'react';
import {
  DealerDetailIcon,
  DealerDetailText,
  DealerDetailWrapper,
  StyledIcon
} from './styled';

interface DealerInformationDetailProps {
  Icon: any;
  label1?: string | JSX.Element;
  label2?: string | JSX.Element;
  textCustom?: JSX.Element;
}
const DealerInformationDetail = ({
  label1,
  label2,
  textCustom,
  Icon
}: DealerInformationDetailProps): JSX.Element => (
  <DealerDetailWrapper>
    <DealerDetailIcon>
      <StyledIcon>
        <Icon iconSize={'20'} />
      </StyledIcon>
    </DealerDetailIcon>
    <DealerDetailText>
      {label1 && (
        <TextCustom
          appearance={TextAppearance.copy100}
          style={{
            fontSize: TextSize.TextSize16,
            lineHeight: TextLineHeight.TextLineHeight24,
            fontWeight: TextWeight.regular
          }}
        >
          {label1}
        </TextCustom>
      )}
      {label2 && (
        <TextCustom
          appearance={TextAppearance.copy100}
          style={{
            fontSize: TextSize.TextSize16,
            lineHeight: TextLineHeight.TextLineHeight24,
            fontWeight: TextWeight.regular
          }}
        >
          {label2}
        </TextCustom>
      )}
      {textCustom && <>{textCustom}</>}
    </DealerDetailText>
  </DealerDetailWrapper>
);

export default DealerInformationDetail;
