import {Breakpoints, styled} from '@ngw6/us-components';

export const StyledNoResultsWrapper: any = styled.div`
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid #dfe4e8;
  margin-bottom: 50px;

  @media (min-width: ${Breakpoints.b1280}px) {
    margin-bottom: 60px;
    padding-top: 20px;
  }
`;

export const StyledModelGroup: any = styled.div`
  min-height: 70px;
  margin-bottom: 35px;
`;

export const StyledHeaderGroup: any = styled.div`
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledHeaderWrapper: any = styled.div`
  margin-bottom: 20px;
  max-width: 335px;

  @media (min-width: ${Breakpoints.b560}px) {
    max-width: 512px;
  }
`;

export const StyledSubHeaderWrapper: any = styled.div`
  max-width: 220px;

  @media (min-width: ${Breakpoints.b560}px) {
    max-width: 380px;
  }
`;

export const StyledImageGroup: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
`;

export const StyledImageWrapper: any = styled.div`
  margin-bottom: 10px;

  > img:nth-child(2) {
    transform: scale(1.1);
  }
`;

export const StyledButtonWrapper: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > :first-child {
    margin-right: 16px;
  }
  & > :last-child {
    margin-left: 16px;
  }
`;

export const StyledButtonSection: any = styled.div`
  width: auto;
`;
