import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerHorizontalAlignment,
  ContainerVerticalAlignment,
  ContainerWrap,
  DisableFocusWrapper,
  Filter,
  LinkButton,
  Text,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight
} from '@ngw6/us-components';
import React, {memo, useEffect, useState} from 'react';
import IncentiveConstants from '../../../hooks-store/incentive-store/actions/incentive-constants';
import {useStore} from '../../../hooks-store/store';
import {disabledScroll} from '../../../utils/general';
import {
  StyledFilterIconSection,
  StyledIconWrapper,
  StyledResultsToolbar
} from './styled';
import {useTranslations} from '../../../services/use-translations';

export const FilterToolbar: React.FC = memo(
  (): JSX.Element => {
    const [
      store,
      {
        [IncentiveConstants.ActivateMobileFilterModal]: activateMobileFilterModal
      }
    ] = useStore();
    const translations = useTranslations().offersSection.filterToolbar;

    /**
     * How many filters are available to see
     * after filter interactions
     */
    const getMatchingOffers = (): number =>
      store.filteredOffers.reduce(
        (next: number, {offerCount}: any) => next + offerCount,
        0
      );

    /**
     * How many filters are selected on the sidebar
     */
    const getAppliedFilterAmount = (): string =>
      store.appliedFilters.length ? `(${store.appliedFilters.length})` : '';

    const [matchingOffers, setMatchingOffers] = useState(getMatchingOffers());

    useEffect(() => {
      setMatchingOffers(getMatchingOffers());
    }, [store.filteredOffers]);

    return (
      <StyledResultsToolbar>
        <Container
          horizontalAlign={ContainerHorizontalAlignment.spaceBetween}
          verticalAlign={ContainerVerticalAlignment.center}
        >
          <Container
            wrap={ContainerWrap.auto}
            gutter={ContainerGutter.static150}
          >
            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.span}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize16,
                  lineHeight: TextLineHeight.TextLineHeight24,
                  fontWeight: TextWeight.light,
                  color: TextColor.inherit
                },
                [Breakpoints.b1280]: {
                  fontSize: TextSize.TextSize20
                }
              }}
            >
              {translations.showing}
            </TextCustom>
            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.span}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize16,
                  lineHeight: TextLineHeight.TextLineHeight24,
                  fontWeight: TextWeight.bold
                },
                [Breakpoints.b1280]: {
                  fontSize: TextSize.TextSize20
                }
              }}
            >
              {` ${matchingOffers} matching `}
            </TextCustom>
            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.span}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize16,
                  lineHeight: TextLineHeight.TextLineHeight24,
                  fontWeight: TextWeight.light,
                  color: TextColor.inherit
                },
                [Breakpoints.b1280]: {
                  fontSize: TextSize.TextSize20
                }
              }}
            >
              {matchingOffers === 1 ? 'offer.' : 'offers.'}
            </TextCustom>
          </Container>
          <StyledFilterIconSection>
            <Text
              appearance={TextAppearance.copy100}
              color={TextColor.primary}
            >
              <DisableFocusWrapper>
                <LinkButton
                  onClick={() => {
                    disabledScroll(true);
                    activateMobileFilterModal(true);
                  }}
                >
                  <StyledIconWrapper>
                    <Filter variant="small" />
                    {`Filter ${getAppliedFilterAmount()}`}
                  </StyledIconWrapper>
                </LinkButton>
              </DisableFocusWrapper>
            </Text>
          </StyledFilterIconSection>
        </Container>
      </StyledResultsToolbar>
    );
  }
);
