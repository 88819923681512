import {
  Breakpoints,
  ButtonAppearance,
  CustomImage,
  DisableFocusWrapper,
  Text,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight,
  buttonPaddingGenerator,
  ButtonCustomLink,
} from '@ngw6/us-components';
import React from 'react';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../../hooks-store/store';
import {
  OfferInformation,
  ImageOfferData,
} from '../../../../hooks-store/typings/incentive-store';
import { AppConstants } from '../../../../utils/app-constants';
import {
  getContrastColorText,
  transformIrisUrl,
} from '../../../../utils/general';
import { NoResults } from './no-results';
import {
  StyledBackground,
  StyledButtonSection,
  StyledButtonWrapper,
  StyledHeadOfferWrapper,
  StyledImageGroup,
  StyledImagePlaceholder,
  StyledImageWrapper,
  StyledTextWrapper,
  StyledTagContainer,
} from './styled';
import { useTranslations } from '../../../../services/use-translations';
import { useTrackingManager } from '../../../../context/use-tracking-manager';
import { onHandleBasicFormButtonLink } from '../../../../utils/tagging-helpers';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';
import { CustomCTAData } from '../../../../config/tier-1/model-order';
import ModelTag from './model-tag';

interface ModelOfferHeadProps {
  readonly offerInformation: OfferInformation;
}

export const ModelOfferHead: React.FC<ModelOfferHeadProps> = (
  props,
): JSX.Element | null => {
  const { faHostServerUrl = '' } = useFeatureAppConfig();
  const { offerInformation } = props;

  const [
    store,
    { [IncentiveConstants.SetModalFormData]: setModalFormData },
  ] = useStore();

  const trackingManager = useTrackingManager();
  const translations = useTranslations().offersSection.modelOfferSection
    .modelOfferHead;

  const [imageMap, setImageMap] = React.useState<ImageOfferData>(
    offerInformation.imageData,
  );

  const isNotStandardModel =
    typeof offerInformation.modelCustomCta !== 'undefined';

  React.useEffect(() => {
    if (offerInformation.imageData) {
      setImageMap(offerInformation.imageData);
    }
  }, [offerInformation.imageData]);

  const setContactDealerFormData = () => {
    setModalFormData(
      {
        modelDisplayName: offerInformation?.modelDisplayName,
        modelKey: offerInformation?.modelKey,
        mediaImageUrl:
          transformIrisUrl(
            imageMap?.modelImage,
            AppConstants.IrisImgPositionE13,
            '960',
            '432',
            imageMap?.modelYear,
            offerInformation.modelKey,
            imageMap?.isNewImage,
          ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
      },
      store.dealer,
      AppConstants.ContactDealerFormType,
      AppConstants.ContactDealerFormName,
    );
    onHandleBasicFormButtonLink(
      offerInformation,
      translations.contactDealer,
      trackingManager,
      store,
    );
  };

  const setGetAQuoteFormData = () => {
    setModalFormData(
      {
        modelDisplayName: offerInformation.modelDisplayName,
        modelKey: offerInformation.modelKey,
        mediaImageUrl:
          transformIrisUrl(
            imageMap?.modelImage,
            AppConstants.IrisImgPositionE13,
            '960',
            '432',
            imageMap?.modelYear,
            offerInformation.modelKey,
            imageMap?.isNewImage,
          ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
      },
      store.dealer,
      AppConstants.GetAQuoteFormType,
      AppConstants.GetAQuoteFormName,
    );
    onHandleBasicFormButtonLink(
      offerInformation,
      translations.getAQuote,
      trackingManager,
      store,
    );
  };

  const setModelCustomCtaFormData = () => {
    onHandleBasicFormButtonLink(
      offerInformation,
      offerInformation.modelCustomCta?.ctaLabel || '',
      trackingManager,
      store,
      offerInformation.modelCustomCta?.url,
    );
  };

  const ctaActions = {
    getAQuote: setGetAQuoteFormData,
    contactDealer: setContactDealerFormData,
    buildReserve: setModelCustomCtaFormData,
  };

  const getCTAs = (): CustomCTAData[] => {
        const modelCustomArrayCta = offerInformation?.modelCustomArrayCta;
    const modelCustomArrayCtaEnabled =
      offerInformation?.modelCustomArrayCtaEnabled;
    const modelCustomCta = offerInformation?.modelCustomCta;

    if (modelCustomArrayCtaEnabled && modelCustomArrayCta) {
      return modelCustomArrayCta;
    } else if (isNotStandardModel) {
      return [
        {
          actionType: 'buildReserve',
          ...modelCustomCta,
        },
      ];
    } else {
            return [
        {
          type: ButtonAppearance.Secondary,
          actionType: 'getAQuote',
          ctaLabel: translations.getAQuote,
        },
        {
          type: ButtonAppearance.Primary,
          actionType: 'contactDealer',
          ctaLabel: translations.contactDealer,
        },
      ];
    }
  };

  const ctas = getCTAs();

  return (
    <StyledHeadOfferWrapper className="header-offer-wrapper">
      {offerInformation.offerCount === 0 ? (
        <NoResults
          offerInformation={offerInformation}
          imageMap={imageMap}
          setContactDealerFormData={setContactDealerFormData}
          setGetAQuoteFormData={setGetAQuoteFormData}
          setModelCustomCtaFormData={setModelCustomCtaFormData}
          ctas={ctas}
          ctasActions={ctaActions}
        />
      ) : (
        <>
          {offerInformation?.modelTag?.isModelTagEnabled && (
            <ModelTag {...offerInformation.modelTag}/>
          )}
          <StyledImageGroup>
            {offerInformation.modelKey !== AppConstants.AllOfferModelKey && (
              <StyledBackground
                data-bg={imageMap?.background}
                backgroundColor={imageMap?.background}
              />
            )}

            <StyledTextWrapper
              headTextColor={getContrastColorText(
                imageMap?.background || '#BD3319',
              )}
              backgroundSection={imageMap?.background || '#BD3319'}
              applyBg={
                offerInformation.modelKey === AppConstants.AllOfferModelKey
              }
            >
              <TextCustom
                appearance={TextAppearance.headline200}
                style={{
                  [Breakpoints.default]: {
                    fontWeight: TextWeight.bold,
                    fontSize: TextSize.TextSize32,
                    lineHeight: TextLineHeight.TextLineHeight36,
                  },
                }}
              >
                {offerInformation.modelDisplayName}
              </TextCustom>
              <TextCustom
                appearance={TextAppearance.headline200}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize32,
                    lineHeight: TextLineHeight.TextLineHeight36,
                    fontWeight: TextWeight.light,
                  },
                }}
              >
                {`${offerInformation.offerCount} ${
                  offerInformation.offerCount === 1 ? 'offer' : 'offers'
                }`}
              </TextCustom>
            </StyledTextWrapper>
            {offerInformation.modelKey !== AppConstants.AllOfferModelKey && (
              <>
                <StyledImagePlaceholder>
                  <StyledImageWrapper className="image-wrapper">
                    <CustomImage
                      alt={offerInformation.modelDisplayName || ''}
                      srcPlaceholder={AppConstants.PlaceHolder480x216 as string}
                      srcErrorImage={
                        faHostServerUrl + AppConstants.Ngw6Logo480x216
                      }
                      src={
                        transformIrisUrl(
                          imageMap?.modelImage,
                          AppConstants.IrisImgPositionE13,
                          '480',
                          '216',
                          imageMap?.modelYear,
                          offerInformation?.modelKey,
                          imageMap?.isNewImage,
                        ) || faHostServerUrl + AppConstants.Ngw6Logo480x216
                      }
                    />
                  </StyledImageWrapper>
                </StyledImagePlaceholder>
                <StyledButtonWrapper className="buttons-wrapper">
                  {ctas?.map((cta: CustomCTAData) => (
                    <StyledButtonSection>
                      <DisableFocusWrapper>
                        <ButtonCustomLink
                          appearance={cta?.type || ButtonAppearance.Secondary}
                          padding={buttonPaddingGenerator(12, 20, 30)}
                          stretchContent
                          href={cta?.url}
                          target={cta?.openNewTab ? '_blank' : '_self'}
                          onClick={
                            cta?.actionType && ctaActions[cta?.actionType]
                          }
                        >
                          <Text
                            color={TextColor.inherit}
                            appearance={TextAppearance.label150}
                          >
                            {cta?.ctaLabel}
                          </Text>
                        </ButtonCustomLink>
                      </DisableFocusWrapper>
                    </StyledButtonSection>
                  ))}
                </StyledButtonWrapper>
              </>
            )}
          </StyledImageGroup>
        </>
      )}
    </StyledHeadOfferWrapper>
  );
};
