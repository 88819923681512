import {Breakpoints, styled} from '@ngw6/us-components';

export const MapWrapper: any = styled.div`
  width: 100%;
  height: 430px;
  overflow: hidden;
  margin-bottom: 80px;

  @media (min-width: ${Breakpoints.b960}px) {
    height: 523px;
    margin-bottom: 0;
  }
`;
