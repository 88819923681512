import {styled, Breakpoints} from '@ngw6/us-components';

interface StyledOfferTypeLabelProps {
  readonly backgroundColor: string;
  readonly textColor: string;
}

interface StyledOpacityWrapperProps {
  isOpacity?: boolean;
}

export const StyledOfferCardWrapper: any = styled.div`
  width: 100%;
  border: 1px solid #dfe4e8;
  margin-top: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 15px);
  position: relative;
`;

export const StyledInformationOfferContainer: any = styled.div`
  width: 100%;
  padding: 30px 20px 0;
`;

export const StyledOfferMicroText: any = styled.div`
  width: 100%;
  margin-bottom: 20px;
  color: #758189;
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledButtonWrapper: any = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dfe4e8;

  & > :first-child {
    margin-right: 5px;
  }
  & > :last-child {
    margin-left: 5px;
  }
`;

export const StyledButtonSection: any = styled.div`
  width: auto;

  @media (min-width: ${Breakpoints.b1280}px) {
    width: 50%;
  }
`;

export const StyledSectionButton: any = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledModelNameWrapper: any = styled.div`
  min-height: 48px;
  margin-bottom: 15px;
`;

export const StyledOfferTitle: any = styled.div`
  min-height: 85px;
  margin-bottom: 15px;
`;

export const StyledOfferSubtitle: any = styled.div`
  margin-bottom: 20px;
`;

export const StyledShowOfferDetails: any = styled.div`
  margin-bottom: 30px;
`;

export const StyledOfferTypeLabelWrapper: any = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledBlackContainer: any = styled.div`
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: 0 10px;
`;

export const StyledOfferTypeLabel: any = styled.div<StyledOfferTypeLabelProps>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
`;

export const StyledOpacityWrapper: any = styled.div<StyledOpacityWrapperProps>`
  ${(props: StyledOpacityWrapperProps) =>
    props.isOpacity &&
    ` position: absolute;
    width: 100%;
    height: calc(100% + 15px);
    top: -15px;
    right: 0;
    background-color: white;
    opacity: 0.5;
    z-index: 1;
  `}
`;

export const StyledInformationWrapper: any = styled.div`
  width: 100%;
`;
