import {History} from 'history';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import {AppConstants} from './app-constants';

/**
 * Add a query parameter to the url
 * @param history React history
 * @param key Key for the query parameter in the url
 * @param value Value for the query parameter in the url
 */
export const addUrlParam = (history: History, key: string, value: string) => {
  const search = getHistorySearch(history);
  const query = queryString.parse(decodeURI(search));
  const param = {...query, [key]: value};
  const path = `${history.location.pathname}?${queryString.stringify(param)}`;
  history.replace(path);
};

/**
 * Add a query parameter to the url
 * @param history React history
 * @param key Key for the query parameter in the url
 * @param value Value for the query parameter in the url
 */
export const addUrlParamWithPush = (
  history: History,
  key: string,
  value: string
) => {
  const search = getHistorySearch(history);
  const query = queryString.parse(decodeURI(search));
  const param = {...query, [key]: value};
  const path = `${history.location.pathname}?${queryString.stringify(param)}`;
  history.push(path);
};

/**
 * Add a query parameter to the url
 * @param history React history
 * @param key Key for the query parameter in the url
 * @param value Value for the query parameter in the url
 */
export const removeUrlParam = (history: History, key: string) => {
  const search = getHistorySearch(history);
  const query = queryString.parse(decodeURI(search));
  const param = _.omit(query, [key]);
  const path = `${history.location.pathname}?${queryString.stringify(param)}`;
  history.push(path);
};

/**
 * Get model using hash property and set it like a model filter selected
 * @param history Router history
 */
export const setModelFromHash = (history: History) => {
  const hash = window.location.hash;
  if (hash) {
    const model = hash.split('=').pop();
    if (model) {
      addUrlParam(history, 'model', model.replace(/-/g, ' '));
    }
  }
};

/**
 * Get value from query parameter using a key
 * @param history React history
 * @param key Key value for query parameter obj
 */
export const getUrlParam = (history: History, key: string): string | null => {
  const search = getHistorySearch(history);
  const query: any = queryString.parse(decodeURI(search));
  if (!isEmpty(query) && query[key]) {
    return query[key];
  }
  return null;
};

/**
 * Get the testMode parameter
 * @param history Router history
 */
export const getTestMode = (history: History) =>
  getUrlParam(history, 'testMode');

/**
 * Get a zip using the location search from react
 * @param history React history
 */
export const getUrlZip = (history: History): string => {
  const search = getHistorySearch(history);
  const query: any = queryString.parse(decodeURI(search));
  if (!isEmpty(query) && query.zip) {
    if (new RegExp(AppConstants.VALID_ZIP_REGEX).test(query.zip)) {
      return query.zip;
    }
  }

  return '';
};

/**
 * Get a zip using the location search from react
 * @param history React history
 */
export const getUrlOffer = (history: History): number | undefined => {
  const search = getHistorySearch(history);
  const query: any = queryString.parse(decodeURI(search));
  if (!isEmpty(query) && query.offer) {
    return query.offer as number;
  }
  return undefined;
};

/**
 * Get the lma query string from url
 * @param history React history
 */
export const getLmaVanityUrl = (history: History): string => {
  const search = getHistorySearch(history);
  const query: any = queryString.parse(decodeURI(search));
  if (!isEmpty(query) && query.lma) {
    return query.lma;
  }

  return '';
};

/**
 * Get the correct format for the searh from router history
 * @param history Router history
 */
export const getHistorySearch = (history: History) => {
  const historyAux: any = history;
  let search = history.location.search
    ? history.location.search
    : historyAux.historyMultiplexer.rootHistory.location.search;
  const query = queryString.parse(decodeURI(search));
  delete query['---'];
  search = isEmpty(query) ? '' : `?${queryString.stringify(query)}`;

  return search.replace(/-app/gi, '');
};
