import React from 'react';
import {useStore} from '../../hooks-store/store';
import {LeftContainerSection} from './left-container-section';
import {RightContainerSection} from './right-container-section';
import {
  LayoutWrapper,
  OverflowWrapper,
  StyledLeftContainer,
  StyledOffersSectionWrapper,
  StyledRightContainer
} from './styled';

export const OffersSection = () => {
  const store = useStore()[0];

  return (
    <OverflowWrapper>
      <LayoutWrapper isTier2={store.tier2 ? true : false}>
        <StyledOffersSectionWrapper>
          <StyledLeftContainer>
            <LeftContainerSection />
          </StyledLeftContainer>
          <StyledRightContainer>
            <RightContainerSection />
          </StyledRightContainer>
        </StyledOffersSectionWrapper>
      </LayoutWrapper>
    </OverflowWrapper>
  );
};
