import {styled} from '@ngw6/us-components';

export const MarkerContainer: any = styled.div`
  font-size: 8px;
  cursor: pointer;
  position: relative;
`;

export const PinContainer: any = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
`;

export const PinMarkerText: any = styled.div`
  width: auto;
  height: 29px;
  border-radius: 14.5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: 'capitalize';
  padding: 0 15px;
  margin-bottom: 11px;
`;
