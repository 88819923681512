import { fetchGraphQL } from "./fetch-graphql";
import { createQueryString } from "./helpers/create-query";
import { handleServerResponse } from "./helpers/handle-server-response";
import { validateGraphQLServer } from "./helpers/validations";
import { PRODUCT_DEALERS_BY_VANITY_URL } from "./queries/dealers-by-vanity-url";
import { FetchDealersLmaByVanityUrl, ServiceConfig } from "./typing";


export const fetchDealersLmaByVanityUrl = async ({vanityUrl, graphQLServer}: FetchDealersLmaByVanityUrl) => {
    const requestBody = createQueryString(PRODUCT_DEALERS_BY_VANITY_URL, {vanityUrl});
    if (!vanityUrl) return []
    try {
      validateGraphQLServer(graphQLServer);
        const response = await fetchGraphQL(graphQLServer as ServiceConfig, requestBody);
        if (response.data && response.data.dealers){
          return handleServerResponse(response.data, false);
        } 
        return handleServerResponse(response, true);
      } catch (error) {
        return handleServerResponse(error, true);
      }
}