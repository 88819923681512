import {SlickSlider} from '@ngw6/us-components';
import React from 'react';
import {
  OfferInformation,
  OffersModel,
  ImageOfferData,
  DealerModel
} from '../../../../hooks-store/typings/incentive-store';
import {AppConstants} from '../../../../utils/app-constants';
import {transformIrisUrl} from '../../../../utils/general';
import {OfferCard} from '../offer-card';
import {StyleCarouselContainer} from './styled';
import {useTrackingManager} from '../../../../context/use-tracking-manager';
import {
  onHandleCarouselLoaded,
  onSlideActionTracking
} from '../../../../utils/tagging-helpers';
import {useFeatureAppConfig} from '../../../../services/use-feature-app-config';
import { useFeatureAppEnvironment } from '../../../../services/use-feature-app-environment';
import { useFeatureServices } from '../../../../context/use-feature-services';
import { ModelOrder, localModelOrder } from '../../../../config/tier-1/model-order';

interface OfferCardSectionProps {
  readonly offerInformation: OfferInformation;
  readonly dealer?: DealerModel;
  readonly isTier1?: boolean;
}

export const OfferCardSection: React.FC<OfferCardSectionProps> = React.memo(
  (props): JSX.Element | null => {
    const {faHostServerUrl = '', InventoryRedirectFAName, InventoryRedirectPageName, modelOrder: modelOrderConfig} = useFeatureAppConfig();
    const {offerInformation, dealer, isTier1} = props;
    const useModelOrder = modelOrderConfig ? modelOrderConfig : localModelOrder;
    const [imageMap, setImageMap] = React.useState<ImageOfferData>(
      offerInformation.imageData
    );
    const { 'navigation-service': navigationService } = useFeatureServices();

    const trackingManager = useTrackingManager();
    /**
   * Use the model name to create a slug
   */
  const getSlug = () => {
    const modelOrderEle: ModelOrder = useModelOrder.filter(
      (ele: ModelOrder) => ele.modelKey === offerInformation.modelKey,
    )[0];

    return modelOrderEle?.modelName?.toLowerCase().replace(/ /g, '-');
  };
    const navigateToInventory = navigationService.navigateTo(
      InventoryRedirectPageName
        ? InventoryRedirectPageName
        : AppConstants.InvRedirectPagename,
      {
        [InventoryRedirectFAName
          ? InventoryRedirectFAName
          : AppConstants.InvRedirectFAName]: `/inventory/results/${getSlug()}`,
      },
    );
  
    const navigateToInventoryHref = navigateToInventory?.createHref();

    React.useEffect(() => {
      if (offerInformation.imageData) {
        setImageMap(offerInformation.imageData);
      }
    }, [offerInformation.imageData]);

    // Tracking Function
    const onHandleSlideAction = (
      type: 'click' | 'swipe',
      direction: string
    ) => {
      onSlideActionTracking(
        type,
        direction,
        offerInformation,
        trackingManager,
        undefined,
        dealer,
        isTier1
      );
      onHandleCarouselLoaded(
        offerInformation,
        trackingManager,
        isTier1,
        undefined,
        dealer
      );
    };

    return (
      <StyleCarouselContainer>
        <SlickSlider
          visibleCards={2.5}
          visibleCardOnTablet={2.5}
          showArrows={true}
          onActionTrackingTrigger={onHandleSlideAction}
        >
          {offerInformation.offers.map((offer: OffersModel) => (
            <OfferCard
              key={offer.dealId}
              modelInventoryHref={navigateToInventoryHref}
              offer={{
                ...offer,
                modelKey: offerInformation?.modelKey,
                modelDisplayName: offerInformation?.modelDisplayName,
                mediaImageUrl:
                  transformIrisUrl(
                    imageMap?.modelImage,
                    AppConstants.IrisImgPositionE13,
                    '960',
                    '432',
                    imageMap?.modelYear,
                    offerInformation?.modelKey,
                    imageMap?.isNewImage
                  ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
                modelCustomCta: offerInformation.modelCustomCta
              }}
              isTier1
            />
          ))}
        </SlickSlider>
      </StyleCarouselContainer>
    );
  }
);
