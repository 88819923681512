import { FeatureAppConfig } from '..';
import { useFeatureAppEnvironment } from './use-feature-app-environment';
const FA_SERVICES_NAME = "fa-services"

export function useFeatureAppConfig(): FeatureAppConfig {
  let { config = { faServicesUrl: '' }, baseUrl, featureServices } = useFeatureAppEnvironment();

  const serviceConfig = featureServices['service-config-provider'];
  if(!config.faServicesUrl) {
    if (serviceConfig && serviceConfig?.configs[FA_SERVICES_NAME] && serviceConfig.configs[FA_SERVICES_NAME]?.urlOrigin) {
      config = {...config, faServicesUrl: serviceConfig?.configs[FA_SERVICES_NAME]?.urlOrigin || ""}
    } else {
      throw new Error("FeatureAppConfig Service Config: No 'fa-service' is configured");
    }
  }
  if (!config?.faHostServerUrl) {
    config = { ...config, faHostServerUrl: baseUrl || '' };
  }
  return config;
}
