export interface OfferTypeModel {
  readonly offerType: string;
  readonly offerName: string;
}

export const localOfferType: OfferTypeModel[] = [
  {offerType: 'lease', offerName: 'Lease'},
  {offerType: 'apr', offerName: 'APR'},
  {offerType: 'other', offerName: 'Other'}
];
