import {SlickSlider} from '@ngw6/us-components';
import React from 'react';
import {
  OfferImagesMapModel,
  localOfferImagesMap
} from '../../../../config/offer-images-map';
import ErrorCodes from '../../../../error-handling/error-codes';
import {validateError} from '../../../../error-handling/error-utils';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import {useStore} from '../../../../hooks-store/store';
import {
  DealerModel,
  ErrorHandlingModel,
  OffersModel,
  TypeMap,
  ImageOfferData,
  OfferInformation
} from '../../../../hooks-store/typings/incentive-store';
import {useFeatureAppConfig} from '../../../../services/use-feature-app-config';
import {useHistory} from '../../../../services/use-history';
import {useTranslations} from '../../../../services/use-translations';
import {AppConstants} from '../../../../utils/app-constants';
import {addUrlParam} from '../../../../utils/navigation-url';
import {NoResults} from '../../../no-results';
import {SectionWithHeader} from '../../../section-with-header';
import {OfferCard} from './offer-card';
import {ErrorComponent} from '../../../../components/error-component';
import {
  getOfferImageDataForTier2,
  getOfferImageMapFromModelsConfig
} from '../../../../utils/general';
import {localModelOrder} from '../../../../config/tier-1/model-order';
import {ModelConfig} from '../../../../typings/model-config';
import {useTrackingManager} from '../../../../context/use-tracking-manager';
import {
  onHandleCarouselLoaded,
  onSlideActionTracking
} from '../../../../utils/tagging-helpers';

interface FeaturedOffersProps {
  offersData: TypeMap<OfferInformation>;
  readonly allOffersHandler?: () => void;
  readonly dealer?: DealerModel;
  readonly vanityUrlName: string;
  readonly errorHandling: ErrorHandlingModel[];
  readonly modelsConfig?: ModelConfig;
}

export const FeaturedOffers: React.FC<FeaturedOffersProps> = React.memo(
  (props): JSX.Element | null => {
    const {
      offersData,
      dealer,
      vanityUrlName,
      errorHandling,
      modelsConfig
    } = props;
    const {[IncentiveConstants.SetModalFormData]: setModalFormData} = useStore(
      false
    )[1];
    const translations = useTranslations().tier2.landing.featuredOffers;
    const history = useHistory();

    // CMS Configuration
    const {modelOrder} = useFeatureAppConfig();
    const offerImagesMap = getOfferImageMapFromModelsConfig(modelsConfig);

    const offerImagesMapConfig: OfferImagesMapModel = offerImagesMap
      ? offerImagesMap
      : localOfferImagesMap;

    const trackingManager = useTrackingManager();

    /**
     * Using the special ofer modelKey the offer image map is gotten
     * @param offer A special offer
     */
    const getOfferImageMap = (
      offer: OffersModel
    ): ImageOfferData | undefined => {
      return getOfferImageDataForTier2(
        offer.modelKey || '',
        offer.dealCarYear[0],
        modelOrder || localModelOrder,
        modelsConfig,
        offerImagesMapConfig
      );
    };

    /**
     * Go to all offers view
     */
    const allOffersHandler = () => {
      history.replace(`${history.location.pathname}all-offers`);
      addUrlParam(history, 'lma', vanityUrlName);
    };

    /**
     * Pick one offer per model
     */
    const getFeaturedOffers = () =>
      Object.keys(offersData)
        .map(key => {
          if (offersData[key].offers.length > 0) {
            return {
              ...offersData[key].offers[0],
              modelKey: offersData[key].modelKey,
              modelDisplayName: offersData[key].modelDisplayName,
              modelCustomCta: offersData[key].modelCustomCta
            };
          }

          return null;
        })
        .filter((offer: any) => offer);

    /**
     * Setup store with information to display dealer modal form
     */
    const setContactDealerFormData = () => {
      setModalFormData(
        null,
        dealer,
        AppConstants.ContactDealerFormType,
        AppConstants.ContactDealerFormName
      );
    };

    const featureOffers: any = getFeaturedOffers();

    if (
      errorHandling.length &&
      validateError(errorHandling, ErrorCodes.FetchOffersError)
    ) {
      return (
        <SectionWithHeader title="Featured Offers">
          <ErrorComponent />
        </SectionWithHeader>
      );
    }

    /**
     * Tracking function for slider actions
     * @param type
     * @param direction
     */
    const onSlideCarouselActionTracking = (
      type: 'click' | 'swipe',
      direction: string
    ) => {
      onSlideActionTracking(
        type,
        direction,
        undefined,
        trackingManager,
        undefined,
        dealer
      );
      onHandleCarouselLoaded(
        {
          modelDisplayName:
            (featureOffers[0] && featureOffers[0].modelName) || undefined
        },
        trackingManager,
        undefined,
        'Featured Offers',
        dealer
      );
    };

    return (
      <SectionWithHeader
        showButton={featureOffers.length > 0}
        btnLabel={translations.offersButtonLabel}
        buttonType="link"
        title={translations.offersButtonTitle}
        btnAction={allOffersHandler}
      >
        <>
          {featureOffers.length ? (
            <SlickSlider
              visibleCards={3.5}
              visibleCardOnTablet={2.5}
              showArrows={true}
              showElementNumber
              onActionTrackingTrigger={onSlideCarouselActionTracking}
            >
              {featureOffers.map((offer: OffersModel) => (
                <OfferCard
                  key={offer.dealId}
                  offer={offer}
                  imageMap={getOfferImageMap(offer)}
                  dealer={dealer}
                  trackingManager={trackingManager}
                />
              ))}
            </SlickSlider>
          ) : (
            <NoResults
              header={translations.noResultsHeader}
              subHeader={translations.noResultsSubHeader}
              firstBtnAction={() => window.open(dealer?.url, '_blank')}
              firstBtnLabel={translations.noResultsBtnLabel1}
              firstBtnPadding={[12, 38, 48, 48]}
              secondBtnAction={setContactDealerFormData}
              secondBtnLabel={translations.noResultsBtnLabel2}
              secondBtnPadding={[12, 20, 30, 30]}
            />
          )}
        </>
      </SectionWithHeader>
    );
  }
);
