import {Breakpoints, styled} from '@ngw6/us-components';

export const StyledSideContainer: any = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.b1280}px) {
    width: 73%;
    margin-right: 27%;
  }
`;

export const StyledInputZipWrapper: any = styled.div`
  display: inline-table;
  width: 100%;
`;

export const StyledInputZipContainer: any = styled.div`
  display: block;
  padding: 0 3px;
  margin-bottom: 50px;

  @media (min-width: ${Breakpoints.b1280}px) {
    margin-bottom: 85px;
  }
`;

export const StyledBreadcrumbWrapper: any = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 40px;
`;
