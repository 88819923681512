import {Breakpoints, styled} from '@ngw6/us-components';

export const StyledOfferCardWrapper: any = styled.div`
  width: 100%;
  border: 1px solid #dfe4e8;
  margin-top: 15px;
  margin-bottom: 50px;
`;

export const StyledInformationOfferContainer: any = styled.div`
  width: 100%;
  padding: 30px 20px 20px;
`;

export const StyledOfferMicroText: any = styled.div`
  width: 100%;
  color: #515a5e;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledCardHead: any = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 45px;
  }
`;
