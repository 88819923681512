import {
  Breakpoints,
  ButtonAppearance,
  ButtonCustom,
  CustomImage,
  DisableFocusWrapper,
  Text,
  TextAlignment,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight,
  buttonPaddingGenerator,
  ButtonCustomLink,
} from '@vw-marketing/us-components';
import React from 'react';
import { OfferInformation } from '../../../../../hooks-store/typings/incentive-store';
import { AppConstants } from '../../../../../utils/app-constants';
import { transformIrisUrl } from '../../../../../utils/general';
import {
  StyledButtonSection,
  StyledButtonWrapper,
  StyledHeaderGroup,
  StyledHeaderWrapper,
  StyledImageGroup,
  StyledImageWrapper,
  StyledModelGroup,
  StyledNoResultsWrapper,
  StyledSubHeaderWrapper,
} from './styled';
import { useTranslations } from '../../../../../services/use-translations';
import {
  CustomCTAData,
  ModelImage,
} from '../../../../../config/tier-1/model-order';
import { useFeatureAppConfig } from '../../../../../services/use-feature-app-config';
import { useIsNationalOffers } from '../../../../../services/use-is-national-offer';

interface NoResultsProps {
  readonly offerInformation: OfferInformation;
  readonly imageMap: ModelImage;
  readonly setContactDealerFormData: () => void;
  readonly setGetAQuoteFormData: () => void;
  readonly setModelCustomCtaFormData: () => void;
  readonly ctas?: CustomCTAData[];
  readonly ctasActions?: any;
}

export const NoResults: React.FC<NoResultsProps> = (
  props,
): JSX.Element | null => {
  const { faHostServerUrl = '' } = useFeatureAppConfig();
  const {
    offerInformation,
    imageMap,
    setContactDealerFormData,
    setGetAQuoteFormData,
    setModelCustomCtaFormData,
    ctas,
    ctasActions,
  } = props;

  const translations = useTranslations().offersSection.modelOfferSection
    .modelOfferHead.noResults;
  const isActiveNational = useIsNationalOffers();
  const isNotStandardModel =
    typeof offerInformation.modelCustomCta !== 'undefined';

  return (
    <StyledNoResultsWrapper>
      <StyledModelGroup>
        <TextCustom
          appearance={TextAppearance.headline200}
          style={{
            [Breakpoints.default]: {
              fontWeight: TextWeight.bold,
              fontSize: TextSize.TextSize32,
              lineHeight: TextLineHeight.TextLineHeight36,
            },
          }}
        >
          {offerInformation.modelDisplayName}
        </TextCustom>
      </StyledModelGroup>
      <StyledHeaderGroup>
        <StyledHeaderWrapper>
          <TextCustom
            appearance={TextAppearance.headline200}
            style={{
              [Breakpoints.default]: {
                fontWeight: TextWeight.bold,
                fontSize: TextSize.TextSize32,
                lineHeight: TextLineHeight.TextLineHeight36,
                textAlign: TextAlignment.center,
              },
            }}
          >
            {translations.noOffers}
          </TextCustom>
        </StyledHeaderWrapper>
        {offerInformation.modelKey !== AppConstants.AllOfferModelKey &&
          !isActiveNational && (
            <StyledSubHeaderWrapper>
              <TextCustom
                appearance={TextAppearance.headline200}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize20,
                    lineHeight: TextLineHeight.TextLineHeight24,
                    fontWeight: TextWeight.regular,
                    textAlign: TextAlignment.center,
                  },
                }}
              >
                {translations.contactOrQuote}
              </TextCustom>
            </StyledSubHeaderWrapper>
          )}
      </StyledHeaderGroup>

      {offerInformation.modelKey !== AppConstants.AllOfferModelKey && (
        <StyledImageGroup>
          <StyledImageWrapper>
            {imageMap && (
              <CustomImage
                alt={offerInformation.modelDisplayName || ''}
                srcPlaceholder={AppConstants.PlaceHolder480x216 as string}
                src={
                  transformIrisUrl(
                    imageMap?.modelImage,
                    AppConstants.IrisImgPositionE13,
                    '480',
                    '216',
                    imageMap?.modelYear,
                    offerInformation?.modelKey,
                    imageMap?.isNewImage,
                  ) || faHostServerUrl + AppConstants.Ngw6Logo480x216
                }
                srcErrorImage={faHostServerUrl + AppConstants.Ngw6Logo480x216}
              />
            )}
          </StyledImageWrapper>
          <StyledButtonWrapper oneElement={isNotStandardModel}>
            {isNotStandardModel ? (
              <StyledButtonSection>
                <DisableFocusWrapper>
                  <ButtonCustomLink
                    appearance={ButtonAppearance.Secondary}
                    href={offerInformation?.modelCustomCta?.url || ''}
                    padding={buttonPaddingGenerator(12, 20, 30)}
                    stretchContent
                    target={
                      offerInformation.modelCustomCta?.openNewTab
                        ? '_blank'
                        : '_self'
                    }
                    onClick={setModelCustomCtaFormData}
                  >
                    <Text
                      color={TextColor.inherit}
                      appearance={TextAppearance.label150}
                    >
                      {offerInformation.modelCustomCta?.ctaLabel}
                    </Text>
                  </ButtonCustomLink>
                </DisableFocusWrapper>
              </StyledButtonSection>
            ) : ctas?.length? (
              <>
                {ctas?.map((cta: CustomCTAData) => (
                  <StyledButtonSection>
                    <DisableFocusWrapper>
                      <ButtonCustomLink
                        appearance={cta?.type || ButtonAppearance.Secondary}
                        padding={buttonPaddingGenerator(12, 20, 30)}
                        stretchContent
                        href={cta?.url}
                        target={cta?.openNewTab ? '_blank' : '_self'}
                        onClick={
                          cta?.actionType && ctasActions[cta?.actionType]
                        }
                      >
                        <Text
                          color={TextColor.inherit}
                          appearance={TextAppearance.label150}
                        >
                          {cta?.ctaLabel}
                        </Text>
                      </ButtonCustomLink>
                    </DisableFocusWrapper>
                  </StyledButtonSection>
                ))}
              </>
            ) : (
              <>
                <StyledButtonSection>
                  <DisableFocusWrapper>
                    <ButtonCustom
                      appearance={ButtonAppearance.Secondary}
                      onClick={() => setGetAQuoteFormData()}
                      padding={buttonPaddingGenerator(12, 20, 30)}
                      stretchContent
                    >
                      <Text
                        color={TextColor.inherit}
                        appearance={TextAppearance.label150}
                      >
                        {translations.getAQuote}
                      </Text>
                    </ButtonCustom>
                  </DisableFocusWrapper>
                </StyledButtonSection>
                <StyledButtonSection>
                  <DisableFocusWrapper>
                    <ButtonCustom
                      appearance={ButtonAppearance.Primary}
                      onClick={() => setContactDealerFormData()}
                      padding={buttonPaddingGenerator(12, 20, 30)}
                      stretchContent
                    >
                      <Text
                        color={TextColor.inherit}
                        appearance={TextAppearance.label150}
                      >
                        {translations.contactDealer}
                      </Text>
                    </ButtonCustom>
                  </DisableFocusWrapper>
                </StyledButtonSection>
              </>
            )}
          </StyledButtonWrapper>
        </StyledImageGroup>
      )}
    </StyledNoResultsWrapper>
  );
};
