import React from 'react';
import InputZip from '../../components/input-zip';
import {LoaderContainer, LoaderSpinner} from '../../components/loader-spinner';
import {useStore} from '../../hooks-store/store';
import {LocalOffers} from './local-offers';
import {
  LayoutWrapper,
  StyledInputZipContainer,
  StyledInputZipWrapper,
  StyledLandingWrapper
} from './styled';
import {useTranslations} from '../../services/use-translations';

export type DealerView = 'map' | 'list';

interface Tier1SimplifiedVersionPros {
  readonly modelKey: string;
  readonly modelName: string;
  readonly carlineId?: string;
}

export const Tier1SimplifiedVersion: React.FC<Tier1SimplifiedVersionPros> = props => {
  const {modelKey, modelName, carlineId} = props;
  const [store] = useStore();
  const translations = useTranslations().tier1simplifiedVersion;

  return (
    <StyledLandingWrapper>
      {store.isStoreCloned && !store.pendingProcessState ? (
        <LayoutWrapper>
          <StyledInputZipWrapper>
            <StyledInputZipContainer>
              <InputZip
                placeholder={translations.inputZipPlaceholder}
                label={translations.inputZipLabel}
                errorText={translations.inputZipError}
                maxLength={5}
              />
            </StyledInputZipContainer>
          </StyledInputZipWrapper>
          <LocalOffers
            offersData={store.offersData}
            dealer={store.dealer}
            modelKey={modelKey}
            modelName={modelName}
            modelsConfig={store.modelsConfig}
            carlineId={carlineId}
          />
        </LayoutWrapper>
      ) : (
        <LoaderContainer>
          <LoaderSpinner />
        </LoaderContainer>
      )}
    </StyledLandingWrapper>
  );
};
