import {Breakpoints, styled} from '@ngw6/us-components';

export const StyledOfferWrapper: any = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-flow: column;
`;
export const OfferNameWrapper: any = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 70px;
  }
`;

export const OfferImageWrapper: any = styled.div`
  margin-bottom: 0;

  > img:nth-child(2) {
    transform: scale(1.1);
    margin-top: -30px;
  }
`;

interface StyledButtonWrapperProps {
  readonly isDetailForm: boolean;
}
export const StyledButtonWrapper: any = styled.div<StyledButtonWrapperProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-bottom: ${(props: StyledButtonWrapperProps) =>
    props.isDetailForm ? '20px' : '32px'};

  & > :first-child {
    margin-right: 16px;
  }
  & > :last-child {
    margin-left: 16px;
  }

  @media (min-width: ${Breakpoints.b560}px) {
    justify-content: center;
  }

  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 32px;
  }
`;

export const StyledButtonSection: any = styled.div`
  min-width: calc(50% - 20px);
  width: auto;

  @media (min-width: ${Breakpoints.b560}px) {
    min-width: auto;
  }
`;
