import { BreakpointWrapper, Breakpoints } from '@ngw6/us-components';
import React from 'react';
import { Breadcrumb } from '../../../components/breadcrumb';
import { InputZip } from '../../../components/input-zip';
import IncentiveConstants from '../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../hooks-store/store';
import { useHistory } from '../../../services/use-history';
import { addUrlParam } from '../../../utils/navigation-url';
import { FilterArea } from '../filter/filter-area';
import { FilterSidebar } from '../filter/filter-sidebar';
import { InfoContent } from '../info-content';
import {
  StyledBreadcrumbWrapper,
  StyledInputZipContainer,
  StyledInputZipWrapper,
  StyledSideContainer,
} from './styled';
import { useTranslations } from '../../../services/use-translations';
import { useIsNationalOffers } from '../../../services/use-is-national-offer';

export function LeftContainerSection(): JSX.Element | null {
  const [
    store,
    { [IncentiveConstants.ClearFilters]: clearFilters },
  ] = useStore();
  const history = useHistory();
  const isNationalOffer = useIsNationalOffers();
  const translations = useTranslations().offersSection.leftContainerSection;

  return (
    <StyledSideContainer>
      {store.tier2 && (
        <>
          <StyledBreadcrumbWrapper>
            <Breadcrumb
              label={translations.breadcrumbLabel}
              iconSize="24"
              breadcrumbEvent={() => {
                clearFilters(history);
                history.push('');
                addUrlParam(
                  history,
                  'lma',
                  store.authoredLmaDetail.vanityUrlName,
                );
              }}
            />
          </StyledBreadcrumbWrapper>
          <InfoContent />
        </>
      )}

      {!isNationalOffer && (
        <StyledInputZipWrapper>
          <StyledInputZipContainer>
            <InputZip
              placeholder={translations.inputZipPlaceholder}
              label={translations.inputZipLabel}
              errorText={translations.inputZipError}
              altErrortext={translations.noDealersError}
              maxLength={5}
            />
          </StyledInputZipContainer>
        </StyledInputZipWrapper>
      )}
      <BreakpointWrapper min={Breakpoints.b1280}>
        <FilterArea />
      </BreakpointWrapper>
      <FilterSidebar />
    </StyledSideContainer>
  );
}
