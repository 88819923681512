import {ThemedStyledProps, styled} from '@ngw6/us-components';

interface ArrowWrapperProps {
  readonly marginLeft?: string;
  readonly marginRight?: string;
}

export const StyledArrowIconWrapper: any = styled.div<ArrowWrapperProps>`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;

    ${(props: ThemedStyledProps<any, any>) =>
      props.marginLeft && {
        marginLeft: `${props.marginLeft}px`
      }}

    ${(props: ThemedStyledProps<any, any>) =>
      props.marginRight && {
        marginRight: `${props.marginRight}px`
      }}
  }

  &:hover {
    svg {
      fill: #0040c5;
    }
  }
`;
