import {
  Breakpoints,
  CustomImage,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight
} from '@ngw6/us-components';
import React from 'react';
import {AppConstants} from '../../utils/app-constants';
import {
  StyledImageContainer,
  StyledMastheadContainer,
  StyledOpacityWrapper,
  StyledSingleLineTitleWrapper
} from './styled';

interface MastheadImageProps {
  imageSrc: string;
  imageAlt: string;
}
const MastheadImage = ({
  imageSrc,
  imageAlt
}: MastheadImageProps): JSX.Element => (
  <StyledImageContainer>
    <CustomImage
      src={imageSrc}
      srcPlaceholder={AppConstants.PlaceHolder1440x300}
      alt={imageAlt}
    />
    <StyledOpacityWrapper />
  </StyledImageContainer>
);

export interface MastheadProps {
  readonly label1?: string;
  readonly label2?: string;
  readonly customText?: JSX.Element;
  readonly desktopImg: string;
  readonly imageAlt: string;
}

export const Masthead = ({
  label1,
  label2,
  customText,
  desktopImg,
  imageAlt
}: MastheadProps) => (
  <StyledMastheadContainer>
    <MastheadImage imageSrc={desktopImg} imageAlt={imageAlt} />

    <StyledSingleLineTitleWrapper>
      {label1 && (
        <TextCustom
          appearance={TextAppearance.headline400}
          style={{
            [Breakpoints.default]: {
              color: TextColor.inherit,
              fontSize: TextSize.TextSize36,
              lineHeight: TextLineHeight.TextLineHeight42,
              fontWeight: TextWeight.light
            },
            [Breakpoints.b1280]: {
              fontSize: TextSize.TextSize48,
              lineHeight: TextLineHeight.TextLineHeight56
            },
            [Breakpoints.b1920]: {
              fontSize: TextSize.TextSize72,
              lineHeight: TextLineHeight.TextLineHeight80
            }
          }}
        >
          {label1}
        </TextCustom>
      )}
      {label2 && (
        <TextCustom
          appearance={TextAppearance.headline400}
          style={{
            [Breakpoints.default]: {
              color: TextColor.inherit,
              fontSize: TextSize.TextSize36,
              lineHeight: TextLineHeight.TextLineHeight42,
              fontWeight: TextWeight.bold
            },
            [Breakpoints.b1280]: {
              fontSize: TextSize.TextSize48,
              lineHeight: TextLineHeight.TextLineHeight56
            },
            [Breakpoints.b1920]: {
              fontSize: TextSize.TextSize72,
              lineHeight: TextLineHeight.TextLineHeight80
            }
          }}
        >
          {label2}
        </TextCustom>
      )}

      {customText && <>{customText}</>}
    </StyledSingleLineTitleWrapper>
  </StyledMastheadContainer>
);
