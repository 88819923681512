
import {styled} from '@ngw6/us-components';

export const MobileModalContainer: any = styled.div`
  position: absolute;
  z-index: 10001;
`;

export const MobileCardContainer: any = styled.div`
  width: 100vw;
  height: 100%;
  padding: 54px 20px 0 20px;
`;

export const NameWrapper:any = styled.div`
    margin-bottom: 12px;
`

export const DealerInfoWrapper: any = styled.div`
         margin-bottom: 71px;
         display: grid;
         grid-row-gap: 6px;
       `;