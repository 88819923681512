// Here is the place where all the action are declared
// Notice that dispatch is calling logic inside reducer

// Incentive constants that are used to be consistent through the app
import {History} from 'history';
import {
  AttributeModel,
  AuthoredLmaDetailModel,
  DealerModel,
  ErrorHandlingModel,
  LocationModel,
  ModalZipDataModel,
  OfferInformation,
  OffersModel,
  TypeMap
} from '../../typings/incentive-store';
import {StoreModel} from '../../typings/store';
import IncentiveConstants from './incentive-constants';
import {ModelConfig} from '../../../typings/model-config';
import { SpecialEventConfig } from '../../..';

/**
 * Declaration of all the possible actions that InsentiveStore is going to handle
 * @param dispatch function that call reducer
 */
const getIncentiveActions = (dispatch: any) => ({
  // Main data
  // tslint:disable-next-line:typedef
  [IncentiveConstants.CloneStoreData](
    initialSatate: StoreModel,
    history: History,
    specialEvent?: SpecialEventConfig
  ) {
    return dispatch(IncentiveConstants.CloneStoreData, {
      initialSatate,
      history,
      specialEvent

    });
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetInitialData](
    pageName: string,
    offersData: TypeMap<OfferInformation>,
    dealers: DealerModel[],
    dealer: DealerModel,
    history: History,
    zip: string,
    ipZip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined,
    specialEvent?: SpecialEventConfig
  ) {
    return dispatch(IncentiveConstants.SetInitialData, {
      pageName,
      offersData,
      dealers,
      dealer,
      history,
      zip,
      ipZip,
      errorHandling,
      modelsConfig,
      specialEvent
    });
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.UpdateStoreByZip](
    offersData: TypeMap<OfferInformation>,
    dealers: DealerModel[],
    dealer: DealerModel,
    history: History,
    zip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined,
    offerId?: number,
    specialEvent?: SpecialEventConfig
  ) {
    return dispatch(IncentiveConstants.UpdateStoreByZip, {
      offersData,
      dealers,
      dealer,
      history,
      zip,
      errorHandling,
      modelsConfig,
      offerId,
      specialEvent
    });
  },

  // Filters functionality
  // tslint:disable-next-line:typedef
  [IncentiveConstants.UpdateAppliedFilter](
    filter: AttributeModel,
    history: History,
    pendingProcessState: boolean
  ) {
    return dispatch(IncentiveConstants.UpdateAppliedFilter, {
      filter,
      history,
      pendingProcessState
    });
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.ClearFilters](
    history: History,
    pendingProcessState: boolean
  ) {
    return dispatch(IncentiveConstants.ClearFilters, {
      history,
      pendingProcessState
    });
  },

  // Modal functionality
  // tslint:disable-next-line:typedef
  [IncentiveConstants.ActivateMobileFilterModal](
    mobileModalFilterActivated: boolean
  ) {
    return dispatch(
      IncentiveConstants.ActivateMobileFilterModal,
      mobileModalFilterActivated
    );
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.CloseContactDealerForm]() {
    return dispatch(IncentiveConstants.CloseContactDealerForm);
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetModalFormData](
    offer: OffersModel,
    dealer: DealerModel,
    formType: string,
    formName: string
  ) {
    return dispatch(IncentiveConstants.SetModalFormData, {
      offer,
      dealer,
      formType,
      formName
    });
  },

  // Modal zip functionality
  // tslint:disable-next-line:typedef
  [IncentiveConstants.UpdateModalZipState](modalZipState: ModalZipDataModel) {
    return dispatch(IncentiveConstants.UpdateModalZipState, modalZipState);
  },

  // General Functions
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetPendingProcessState](pendingProcessState: boolean) {
    return dispatch(
      IncentiveConstants.SetPendingProcessState,
      pendingProcessState
    );
  },

  // =====================Tier 2 ======================= //
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetInitialDataTier2](
    offersData: TypeMap<OfferInformation>,
    serviceOffersData: OffersModel[],
    dealers: DealerModel[],
    dealer: DealerModel,
    authoredLmaDetail: AuthoredLmaDetailModel,
    location: LocationModel,
    history: History,
    zip: string,
    ipZip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined
  ) {
    return dispatch(IncentiveConstants.SetInitialDataTier2, {
      offersData,
      serviceOffersData,
      dealers,
      dealer,
      authoredLmaDetail,
      location,
      history,
      zip,
      ipZip,
      errorHandling,
      modelsConfig
    });
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.UpdateStoreByZipTier2](
    offersData: TypeMap<OfferInformation>,
    serviceOffersData: OffersModel[],
    dealers: DealerModel[],
    dealer: DealerModel,
    authoredLmaDetail: AuthoredLmaDetailModel,
    location: LocationModel,
    history: History,
    zip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined,
    offerId: number | undefined
  ) {
    return dispatch(IncentiveConstants.UpdateStoreByZipTier2, {
      offersData,
      serviceOffersData,
      dealers,
      dealer,
      authoredLmaDetail,
      location,
      history,
      zip,
      errorHandling,
      modelsConfig,
      offerId
    });
  }
});

export default getIncentiveActions;
