import {SpinnerIcon, Text, TextAppearance} from '@ngw6/us-components';
import React from 'react';
import {
  StyledModalWrapper,
  StyledSpinnerContainer,
  StyledSpinnerWrapper
} from './styled';

interface LoaderModalProps {
  readonly label?: string;
}

export const LoaderSpinnerModal: React.FC<LoaderModalProps> = (
  props
): JSX.Element => {
  const {label} = props;

  return (
    <StyledModalWrapper>
      <StyledSpinnerContainer>
        <StyledSpinnerWrapper>
          <SpinnerIcon iconSize="60" />
          <Text appearance={TextAppearance.copy200}>
            {label ? label : 'Loading...'}
          </Text>
        </StyledSpinnerWrapper>
      </StyledSpinnerContainer>
    </StyledModalWrapper>
  );
};
