import {
  Breakpoints,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight
} from '@ngw6/us-components';
import React from 'react';
import {OffersModel} from '../../../../../../hooks-store/typings/incentive-store';
import {
  StyledCardHead,
  StyledInformationOfferContainer,
  StyledOfferCardWrapper,
  StyledOfferMicroText
} from './styled';

interface ServiceOfferDetailsCardProps {
  readonly offer: OffersModel;
}

export const ServiceOfferDetailsCard: React.FC<ServiceOfferDetailsCardProps> = (
  props
): JSX.Element | null => {
  const {offer} = props;

  return (
    <>
      <StyledCardHead>
        <TextCustom
          appearance={TextAppearance.headline200}
          tag={TextTag.h2}
          style={{
            [Breakpoints.default]: {
              fontWeight: TextWeight.bold,
              color: TextColor.primary,
              fontSize: TextSize.TextSize32,
              lineHeight: TextLineHeight.TextLineHeight36
            }
          }}
        >
          Special Offer Details
        </TextCustom>
      </StyledCardHead>
      <StyledOfferCardWrapper>
        <StyledInformationOfferContainer>
          <StyledOfferMicroText>
            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.h2}
              style={{
                [Breakpoints.default]: {
                  fontWeight: TextWeight.bold,
                  color: TextColor.primary,
                  fontSize: TextSize.TextSize24,
                  lineHeight: TextLineHeight.TextLineHeight28
                }
              }}
            >
              {offer.dealTitle}
            </TextCustom>

            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.p}
              style={{
                [Breakpoints.default]: {
                  color: TextColor.primary,
                  fontSize: TextSize.TextSize14,
                  fontWeight: TextWeight.regular,
                  lineHeight: TextLineHeight.TextLineHeight20
                }
              }}
            >
              {offer.dealSubtitle}
            </TextCustom>

            {offer.dealLegal && (
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight20,
                    fontWeight: TextWeight.regular,
                    color: TextColor.inherit
                  }
                }}
              >
                {offer.dealLegal}
              </TextCustom>
            )}
          </StyledOfferMicroText>
        </StyledInformationOfferContainer>
      </StyledOfferCardWrapper>
    </>
  );
};
