import { ButtonAppearance } from '@ngw6/us-components';
import { AppConstants } from '../../utils/app-constants';

export interface ModelImage {
  readonly modelImage: string;
  readonly modelYear?: string;
  readonly background: string;
  readonly modelImageAlt?: string;
  readonly isNewImage?: boolean;
}

type ActionType = 'getAQuote' | 'contactDealer' | 'buildReserve';

export interface CustomCTAData {
  url?: string;
  ctaLabel?: string;
  openNewTab?: boolean;
  type?: ButtonAppearance;
  actionType?: ActionType;
}

export interface ModelTag {
  label: string;
  background?: string;
  isModelTagEnabled: boolean;
  markdownText?:string;
}
export interface ModelOrder {
  readonly modelKey: string;
  readonly modelName: string;
  readonly carlineId?: string;
  readonly modelImage?: ModelImage;
  readonly isStandard?: boolean;
  readonly modelTag?: ModelTag;
  readonly customCTAData?: CustomCTAData;
  readonly modelCustomArrayCta?: CustomCTAData[];
  readonly modelCustomArrayCtaEnabled?: boolean;
}

export const localModelOrder: ModelOrder[] = [
  {
    modelKey: 'id4',
    modelName: 'ID.4',
    isStandard: false,
    customCTAData: {
      url: 'https://qa.vw.com/pre-order/',
      ctaLabel: 'Build & Reserve',
      openNewTab: true,
    },
    modelTag: {
      label: 'Other Tag',
      isModelTagEnabled: true,
      background: 'red',
    },
  },
  {
    modelKey: 'tiguan',
    modelName: 'Tiguan',
    carlineId: '31120',
    modelCustomArrayCtaEnabled: true,
    modelCustomArrayCta: [
      {
        url: 'http://www.test.com',
        ctaLabel: 'CTA Placeholder 1',
        openNewTab: true,
        type: 'Secondary',
      },
      {
        ctaLabel: 'CTA Placeholder 2',
        openNewTab: true,
        type: 'Primary',
        actionType: 'contactDealer',
      },
    ],
  },
  {
    modelKey: 'acs',
    modelName: 'Atlas Cross Sport',
    carlineId: '31692',
    modelTag: {
      label: 'Placeholder Tag',
      isModelTagEnabled: true,
      background: '#4CC7F4',
      markdownText: "<div><h3> Placeholder</h3> Tag</div>"
    },
  },
  {
    modelKey: 'atlas',
    modelName: 'Atlas',
    carlineId: '31691',
    modelTag: {
      label: 'Placeholder Tag',
      isModelTagEnabled: true,
      background: '#4CC7F4',
    },
    modelCustomArrayCtaEnabled: true,
    modelCustomArrayCta: [
      {
        url: 'http://www.test.com',
        ctaLabel: 'CTA Placeholder 1',
        openNewTab: true,
        type: 'Secondary',
      },
      {
        ctaLabel: 'CTA Placeholder 2',
        openNewTab: true,
        type: 'Primary',
        actionType: 'contactDealer',
      },
    ],
  },
  { modelKey: 'jetta', modelName: 'Jetta', carlineId: '30906' },
  { modelKey: 'jettagli', modelName: 'Jetta GLI', carlineId: '30907', modelTag: {
    label: 'Other Tag',
    isModelTagEnabled: true,
    background: 'red',
  } },
  { modelKey: 'passat', modelName: 'Passat', carlineId: '31301' },
  { modelKey: 'golfgti', modelName: 'Golf GTI', carlineId: '30302' },
  { modelKey: 'golf', modelName: 'Golf', carlineId: '30301' },
  { modelKey: 'arteon', modelName: 'Arteon', carlineId: '31505' },
  {
    modelKey: 'all',
    modelName: 'All Models',
    modelImage: {
      background: '#dfe4e8',
      modelImage: AppConstants.Ngw6Logo480x216,
      modelImageAlt: 'All Models',
    },
  },
];
